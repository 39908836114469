import http from './http';

const path = '/clients';

async function create(companyId, data) {
  const res = await http.post(`${path}/${companyId}`, data);
  return res.data;
}

async function list(companyId, data) {
  const res = await http.get(`${path}/${companyId}`, data);
  return res.data;
}

async function remove(companyId, clientId) {
  const res = await http.delete(`${path}/${companyId}/${clientId}`);
  return res.data;
}

export default {
  create,
  list,
  remove,
};
