<template>
  <router-view />
</template>

<script>
import auth from '@/api/auth';

export default {
  name: 'App',
  metaInfo: {
    title: 'App',
    titleTemplate: function (chunk) {
      return `${this.$t(chunk)} | Homestretch`;
    },
    meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }],
    link: [{ rel: 'favicon', href: '/favicon.ico' }],
  },
  created() {
    auth.init();
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
