<template>
  <b-container fluid>
    <div class="mb-4">
      <h1>{{ $t('Invoices') }}</h1>
    </div>

    <b-alert v-if="error" :show="true" variant="danger">
      {{ error.message }}
    </b-alert>

    <b-table
      class="text-center"
      show-empty
      stacked="md"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @filtered="onFiltered"
    >
      <template v-slot:cell(actions)="row">
        <b-button v-if="row.item.status === 'PENDING'" @click.stop="approve(row.item.invoice_id, $event.target)">
          {{ $t('Approve payment') }}
        </b-button>
      </template>
    </b-table>

    <div>
      <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" align="center" />
    </div>
  </b-container>
</template>

<script>
import api from '@/api';
import subDays from 'date-fns/sub_days';
import startOfMonth from 'date-fns/start_of_month';
import subMonths from 'date-fns/sub_months';
import lastDayOfMonth from 'date-fns/last_day_of_month';

export default {
  name: 'Invoices',

  metaInfo: {
    title: 'Invoices',
  },

  data() {
    return {
      error: null,
      datePicker: '',
      items: [],
      shortcuts: [
        {
          text: 'Today',
          start: new Date(),
          end: new Date(),
        },
        {
          text: 'Yesterday',
          start: subDays(new Date(), 1),
          end: subDays(new Date(), 1),
        },
        {
          text: 'Last 7 days',
          start: subDays(new Date(), 7),
          end: subDays(new Date(), 1),
        },
        {
          text: 'Last 30 days',
          start: subDays(new Date(), 30),
          end: subDays(new Date(), 1),
        },
        {
          text: 'This month',
          start: startOfMonth(new Date()),
          end: new Date(),
        },
        {
          text: 'Prev month',
          start: startOfMonth(subMonths(new Date(), 1)),
          end: lastDayOfMonth(subMonths(new Date(), 1)),
        },
      ],
      currentPage: 1,
      perPage: 25,
      totalRows: 0,
      pageOptions: [10, 25, 50, 100, 1000],
      sortBy: null,
      sortDesc: false,
      filter: null,
    };
  },

  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },

    fields() {
      return [
        { key: 'invoice_number', label: '#', sortable: true },
        {
          key: 'created_at',
          label: 'Дата',
          sortable: true,
          formatter: (value) => value && this.$d(value, 'date'),
        },
        { key: 'company_name', label: this.$t('Company'), sortable: true },
        { key: 'type', label: this.$t('Service'), sortable: true },
        { key: 'paid_slots', label: this.$t('Paid slots'), sortable: true },
        { key: 'cost', label: this.$t('Cost'), sortable: true },
        { key: 'currency', label: this.$t('Currency'), sortable: true },
        { key: 'status', label: this.$t('Status'), sortable: true },
        { key: 'actions', label: this.$t('Actions') },
      ];
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      this.items = await api.invoice.list();
    },

    async approve(invoiceId, button) {
      try {
        this.error = null;
        button.disabled = true;
        await api.invoice.approve(invoiceId);
        await this.fetchData();
      } catch (err) {
        this.error = err;
      } finally {
        button.disabled = false;
      }
    },

    async approveCustomInvoice(invoiceId, button) {
      try {
        this.error = null;
        button.disabled = true;
        await api.invoice.approveCustomInvoice(invoiceId);
        await this.fetchData();
      } catch (err) {
        this.error = err;
      } finally {
        button.disabled = false;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
