<template>
  <b-container fluid>
    <b-breadcrumb :items="breadcrumbs" />

    <b-alert v-if="error" show variant="danger" class="mb-0">
      {{ error }}
    </b-alert>

    <company-edit-rules v-if="!loading && company" :item="company" />

    <b-spinner v-if="loading" variant="primary" />
  </b-container>
</template>

<script>
import api from '@/api/company';

import CompanyEditRules from '@/components/CompanyEditRules';

export default {
  name: 'CompanyRules',

  components: {
    CompanyEditRules,
  },

  data() {
    return {
      loading: false,
      error: null,
      company: null,
    };
  },

  created() {
    this.fetchData();
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('Companies'),
          to: { name: 'companies' },
        },
        {
          text: this.$t('Company') + (this.company ? ': ' + this.company.name : ''),
          to: { name: 'company', params: { id: this.$route.params.id } },
        },
        {
          text: this.$t('Editing rules'),
          active: true,
        },
      ];
    },
  },

  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const { data } = await api.get(this.$route.params.id);
        this.company = data;

        this.loading = false;
      } catch (error) {
        this.error = error;

        this.loading = false;
      }
    },
  },
};
</script>
