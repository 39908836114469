<template>
  <div>
    <div class="mb-4">
      <h1>{{ $t('Company information') }}</h1>
    </div>

    <b-row>
      <b-col sm="6">
        <b>{{ $t('Company name') }}</b>
      </b-col>
      <b-col sm="6">{{ info.name }}</b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <b>{{ $t('Domain') }}</b>
      </b-col>
      <b-col sm="6">{{ info.domain }}</b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <b>{{ $t('Country') }}</b>
      </b-col>
      <b-col sm="6">{{ info.country }}</b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <b>{{ $t('Created') }}</b>
      </b-col>
      <b-col sm="6">{{ $d(info.created_at, 'date') }}</b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <b>{{ $t('Owner') }}</b>
      </b-col>
      <b-col sm="6">{{ ownerFullName }}</b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <b>{{ $t('Contacts') }}</b>
      </b-col>
      <b-col sm="6">{{ info.owner.email }} {{ phones }}</b-col>
    </b-row>
    <b-row v-if="isRussian">
      <b-col sm="6">
        <b>{{ $t('Sms balance') }} </b>
      </b-col>
      <b-col sm="6">{{ smsCount }}</b-col>
    </b-row>
    <b-row v-if="isRussian">
      <b-col sm="6">
        <b>{{ $t('Trips balance') }} </b>
      </b-col>
      <b-col sm="6">{{ tripsCount }}</b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'CompanyBasicInfo',

  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    smsCount: {
      type: Number,
      required: true,
    },
    tripsCount: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ownerFullName() {
      return `${this.info.owner.last_name} ${this.info.owner.first_name}`;
    },

    isRussian() {
      return this.info.country === 'ru';
    },

    phones() {
      const tmp = [this.info.owner.phone];
      if (Array.isArray(this.info.owner.contacts) && this.info.owner.contacts.length) {
        for (const phone of this.info.owner.contacts[0].phones) {
          if (phone.phone) {
            tmp.push(phone.phone);
          }
        }
      }
      return tmp.join(', ');
    },
  },
};
</script>
