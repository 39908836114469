<template>
  <div>
    <header>
      <b-navbar toggleable="lg" type="dark" variant="dark" fixed="top">
        <b-navbar-brand to="/">HomeStretch</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-text>{{ userLogin }}</b-nav-text>

            <b-nav-item-dropdown :text="currentLocale" right>
              <b-dropdown-item @click.prevent="changeLocale('en')">EN</b-dropdown-item>
              <b-dropdown-item @click.prevent="changeLocale('ru')">RU</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </header>

    <div class="container-fluid">
      <div class="row">
        <nav class="col-sm-3 col-md-2 d-none d-sm-block bg-light sidebar">
          <MainNav />
        </nav>

        <main role="main" class="col-sm-9 ml-sm-auto col-md-10 py-4">
          <router-view />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MainNav from '@/components/MainNav';

export default {
  name: 'Main',

  components: { MainNav },

  metaInfo: {
    title: 'Main',
  },

  data() {
    return {
      username: null,
      avatar: null,
      dark: false,
      theme: 'primary',
      mini: false,
      drawer: true,
      locales: ['en-US', 'zh-CN'],
      colors: ['blue', 'green', 'purple', 'red'],
    };
  },

  computed: {
    message: () => 'message',
    currentLocale() {
      return this.$i18n.locale.toUpperCase();
    },
    ...mapState('auth', ['userLogin']),
  },

  methods: {
    changeLocale(to) {
      // global.helper.ls.set('locale', to);
      this.$i18n.locale = to;
    },
  },
};
</script>

<style>
/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: 50px;
}

.sidebar {
  position: fixed;
  top: 51px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  border-right: 1px solid #eee;
}
</style>
