<template>
  <b-container fluid>
    <h1>{{ $t('Settings logs') }}</h1>
    <!-- User Interface controls -->
    <base-grid :callback="fetchData" :fields="fields">
      <template v-slot:cell(account)="{ item }">
        <div v-if="item.account">
          {{ item.account.fullName }}
          <em>
            (<span v-if="item.adminType && item.adminType === 'adminLogin' && item.user">{{ item.user.login }}</span>
            <span v-if="item.adminType && item.adminType === 'debugLogin'">sudo</span>)
          </em>
        </div>

        <div v-else-if="item.user">
          <em>{{ item.user.login }}</em>
        </div>
      </template>
    </base-grid>
  </b-container>
</template>

<script>
import api from '@/api';
import baseGridComponent from '@/components/BaseGrid';

export default {
  components: {
    baseGrid: baseGridComponent,
  },

  data() {
    return {};
  },

  computed: {
    fields() {
      return [
        {
          key: 'createdAt',
          label: this.$t('Date'),
          sortable: true,
          sortDirection: 'desc',
          formatter: (value) => this.$d(value, 'long'),
        },
        { key: 'account', label: this.$t('Account'), sortable: false },
        { key: 'change', label: this.$t('Change'), sortable: false },
      ];
    },
  },

  async created() {},

  methods: {
    async fetchData(options) {
      return api.company.getSettingsLogs(this.$route.params.id, options);
    },
  },
};
</script>
