<template>
  <b-container fluid>
    <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    <div class="mb-4">
      <h1>{{ $t('Companies') }}</h1>
    </div>
    <!-- User Interface controls -->
    <base-grid ref="grid" :callback="fetchData" :fields="fields" :has-filter="true" :on-row-click="details">
      <b-dropdown slot="additionalFilters" class="dropdown" variant="outline">
        <template #button-content>
          <b-icon :icon="status.length === 0 ? 'funnel' : 'funnel-fill'" aria-hidden="true" />
          {{ $t('Status') }}
        </template>

        <b-dropdown-form>
          <b-form-checkbox-group :options="statusOptions" v-model="status" />
        </b-dropdown-form>
      </b-dropdown>

      <b-btn slot="btnCreate" to="/companies/create" variant="primary">{{ $t('Create') }}</b-btn>
    </base-grid>
  </b-container>
</template>

<script>
import api from '@/api';
import BaseGrid from '@/components/BaseGrid';
import { BillingStatus } from '@/api/company';

export default {
  components: {
    BaseGrid,
  },

  data() {
    return {
      status: [],
      breadcrumbs: [
        {
          text: this.$t('Companies'),
          active: true,
        },
      ],
    };
  },

  computed: {
    fields() {
      return [
        { key: 'dbId', label: 'ID', sortable: true, sortDirection: 'desc' },
        {
          key: 'createdAt',
          label: this.$t('Created'),
          sortable: true,
          formatter: (value) => value && this.$d(value, 'date'),
        },
        { key: 'domain', label: this.$t('Domain'), sortable: true },
        { key: 'title', label: this.$t('Company name'), sortable: true },
        { key: 'owner.fullName', label: this.$t('Owner'), sortable: true },
        { key: 'country', label: this.$t('Country'), sortable: true },
        {
          key: 'billingInfo.status',
          label: this.$t('Status'),
          sortable: true,
          formatter: (value) => value && this.$t(`BillingStatus.${value}`),
        },
        {
          key: 'billingInfo.currentPeriodEnd',
          label: this.$t('Validity'),
          sortable: true,
          formatter: (value) => value && this.$d(value, 'standard'),
        },
        { key: 'billingInfo.paidSlots', label: this.$t('Paid slots'), class: 'text-center', sortable: true },
        { key: 'billingInfo.activeAccounts', label: this.$t('Billing accounts'), class: 'text-center', sortable: true },
        { key: 'totalAccounts', label: this.$t('Accounts'), class: 'text-center' },
        { key: 'totalDrivers', label: this.$t('Drivers'), class: 'text-center' },
        { key: 'totalTrips', label: this.$t('Trips'), class: 'text-center' },
        { key: 'totalPoints', label: this.$t('Points'), class: 'text-center' },
        {
          key: 'totalDistance',
          label: this.$t('Total distance'),
          class: 'text-center',
          formatter: (value) => this.$n(value, 'distance'),
        },
        {
          key: 'totalDuration',
          label: this.$t('Total duration'),
          class: 'text-center',
          formatter: (value) => this.$d(value, 'duration'),
        },
      ];
    },

    statusOptions() {
      return Object.entries(BillingStatus).map((obj) => ({ text: this.$t(`BillingStatus.${obj[1]}`), value: obj[0] }));
    },
  },

  methods: {
    fetchData(options) {
      options.status = this.status;
      return api.company.list(options);
    },

    details(item) {
      this.$router.push(this.$route.path + '/' + item.dbId);
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    add() {
      this.$router.push(this.$route.path + '/new');
    },
  },

  watch: {
    status() {
      this.$refs.grid.refresh();
    },
  },
};
</script>

<style>
.dropdown button {
  border-color: rgb(206, 212, 218);
  color: rgb(73, 80, 87);
}
</style>
