<template>
  <div class="text-center mt-5">
    <div>
      <h2><a href="/">HomeStretch</a></h2>
    </div>
    <div class="m-3">
      <img class="img-thumbnail" src="@/assets/404.png" />
    </div>
    <div>
      <h1 class="font-weight-bold">404. {{ $t('Page not found') }}</h1>
      <h5>
        <a href="/">{{ $t('Back to the homepage') }}</a>
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
