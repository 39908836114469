import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/styles/main.css';

import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import App from './App';
import router from './router';
import i18n from './i18n';
import store from './store';
import VueSubmit from './components/VueSubmit';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.component('v-submit', VueSubmit);

Vue.mixin({
  methods: {
    toast(description) {
      this.$root.$bvToast.toast(description, {
        title: this.$t('Status'),
        variant: 'success',
        autoHideDelay: 2000,
      });
    },
  },
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  template: '<App/>',
  components: { App },
  created() {
    global.$t = this.$t;
  },
});
