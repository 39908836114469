import http from './http';

const path = '/users';

async function list(data) {
  const res = await http.get(path, {
    params: data,
  });
  return res.data;
}

async function create(data) {
  const res = await http.post(path, data);
  return res.data;
}

async function get(id) {
  const res = await http.get(`${path}/${id}`);
  return res.data;
}

async function update(id, data) {
  const res = await http.put(`${path}/${id}`, data);
  return res.data;
}

async function remove(id) {
  const res = await http.delete(`${path}/${id}`);
  return res.data;
}

export default {
  list,
  create,
  get,
  update,
  remove,
};
