import axios from 'axios';

const config = {
  debug: {
    mock: false && process.env.NODE_ENV === 'development',
    http: false,
  },
};

if (config.debug.mock) {
  require('./mock');
}

class APIError extends Error {
  constructor(response) {
    super((response.data && response.data.message) || 'API Error');
    this.status = response.status;
    this.data = response.data;
    this.errors = response.data.errors;
  }
}

const http = axios.create({
  baseURL: '/api/v2',
  timeout: 10000,
  withCredentials: true,
  // headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
});

http.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(new APIError(error.response)),
);

function setToken(token) {
  http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

function setCSRFToken(token) {
  http.defaults.headers.common['X-CSRF-Token'] = token;
}

const pagingParams = ({ perPage, currentPage, sortBy, sortDesc, filter }) => ({
  page: currentPage - 1,
  pageSize: perPage,
  sort: sortBy,
  sortDesc: sortDesc ? 1 : 0,
  filter,
});

export default http;
export { setToken, setCSRFToken, pagingParams };
