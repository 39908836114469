<template>
  <b-modal :visible="true" :hide-header="true" :hide-footer="true" :no-close-on-backdrop="true" :no-close-on-esc="true">
    <b-alert :show="error" variant="danger">{{ errorText }}</b-alert>

    <b-form @submit.prevent="onSubmit">
      <b-form-group label-for="inputLogin" :label="$t('Login')">
        <b-form-input id="inputLogin" v-model="login" type="text" class="form-control" :placeholder="$t('Login')" />
      </b-form-group>

      <b-form-group label-for="inputPassword" :label="$t('Password')">
        <b-form-input
          id="inputPassword"
          v-model="password"
          type="password"
          class="form-control"
          :placeholder="$t('Password')"
        />
      </b-form-group>

      <v-submit :progress="progress">{{ $t('Submit') }}</v-submit>
    </b-form>
  </b-modal>
</template>

<script>
import api from '@/api';

export default {
  name: 'Login',

  metaInfo: {
    title: 'Login',
  },

  data() {
    return {
      login: '',
      password: '',
      progress: 0,
      error: false,
      errorText: '',
    };
  },

  methods: {
    async onSubmit() {
      try {
        this.error = false;
        this.progress = 1;
        await api.auth.verify(this.login, this.password);
        this.$router.push(this.$route.query.redirect || '/');
      } catch (err) {
        this.error = true;
        this.errorText = err.message;
      }
      this.progress = 0;
    },
  },
};
</script>
