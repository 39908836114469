<template>
  <b-container>
    <b-alert v-if="error" class="mt-3" show variant="danger">
      {{ error.message }}
    </b-alert>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="company_name_edit">{{ $t('company_name') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="company_name_edit" v-model="editedSubscription.company_name" type="text" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="itn_edit">{{ $t('itn') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="itn_edit" v-model="editedSubscription.itn" type="number" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="kpp_edit">{{ $t('kpp') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="kpp_edit" v-model="editedSubscription.kpp" type="number" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="legal_address_edit">{{ $t('legal_address') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="legal_address_edit" v-model="editedSubscription.legal_address" type="text" required />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <v-submit @click.prevent="changePaymentMethods" :progress="progress" class="mt-3">
          {{ $t('Save') }}
        </v-submit>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { cloneDeep } from 'lodash';
import company from '@/api/company';

export default {
  props: {
    id: {
      type: String,
    },
  },

  data() {
    return {
      editedSubscription: {},
      progress: 0,
      error: null,
    };
  },

  methods: {
    async changePaymentMethods() {
      try {
        this.progress = 1;
        this.error = null;

        await company.patchCompanyPaymentMethods(this.id, this.editedSubscription);

        this.toast(this.$t('Invoice successfully edited'));
      } catch (err) {
        this.error = err;
      }
      this.progress = 0;
    },
  },

  async created() {
    const paymentMethods = await company.getCompanyPaymentMethods(this.id);
    this.editedSubscription = cloneDeep(paymentMethods);
  },
};
</script>
