<template>
  <b-container fluid>
    <div class="mb-4">
      <h1>{{ $t('Info') }}</h1>
    </div>

    <b-card v-if="mobileVersions.length" :title="$t('Mobile versions')" class="mb-2">
      <b-col md="6">
        <div v-for="mobileVersion in mobileVersions" :key="mobileVersion.os">
          <b-row>
            <b-col sm="6">
              <b>{{ $t(mobileVersion.os) }}:</b>
            </b-col>

            <b-col>
              <span
                v-b-tooltip.hover.right.v-danger
                :title="mobileVersion.error"
                :class="`text-${mobileVersion.error ? 'danger' : 'success'}`"
              >
                {{ mobileVersion.version }}
              </span>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-card>
  </b-container>
</template>

<script>
import info from '@/api/info';

export default {
  name: 'Info',

  data() {
    return {
      mobileVersions: [],
    };
  },

  async created() {
    await this.fetchInfoData();
  },

  methods: {
    async fetchInfoData() {
      const result = await info.getInfo();

      if (result.mobileVersions.length) {
        this.mobileVersions = result.mobileVersions;
      }
    },
  },
};
</script>
