<template>
  <b-container fluid>
    <div class="mb-4">
      <h1>{{ $t('Users') }}</h1>
    </div>

    <base-grid :callback="fetchUsers" :fields="fields" :has-filter="true" :on-row-click="details">
      <b-btn slot="btnCreate" to="/users/create" variant="primary">{{ $t('Create') }}</b-btn>
    </base-grid>
  </b-container>
</template>

<script>
import api from '@/api/user';
import baseGridComponent from '@/components/BaseGrid';

export default {
  name: 'Users',

  metaInfo: {
    title: 'Users',
  },

  computed: {
    fields() {
      return [
        { key: 'id', label: this.$t('ID'), sortable: true },
        { key: 'first_name', label: this.$t('First name'), sortable: true },
        { key: 'last_name', label: this.$t('Last name'), sortable: true },
        { key: 'role', label: this.$t('Role'), sortable: true },
      ];
    },
  },

  components: {
    baseGrid: baseGridComponent,
  },

  methods: {
    async fetchUsers(options) {
      return api.list(options);
    },

    details(item) {
      this.$router.push(this.$route.path + '/' + item.id);
    },
  },
};
</script>
