<template>
  <div class="roles-form">
    <b-container v-model="isValid">
      <b-container grid-list-sm class="pa-4">
        <b-alert class="mb-4" :value="error" dismissible>
          {{ error }}
        </b-alert>

        <b-form-input v-model.trim="form.name" :rules="formRules.name" required>
          <template #label>
            <strong class="red--text">* </strong>
            {{ $t('roles.field.name') }}
          </template>
        </b-form-input>

        <ul v-if="!isLoading && ruleGroups" class="group">
          <template>
            <li v-for="(group, groupIndex) in ruleGroups" :key="groupIndex">
              <b-form-checkbox
                v-model="group.selected"
                :disabled="group.disabled"
                :indeterminate="group.indeterminate"
                @change="changeGroup(group)"
                hide-details
                >{{ group.title }}
              </b-form-checkbox>

              <ul class="rule">
                <li v-for="(rule, ruleIndex) in group.rules" :key="ruleIndex">
                  <b-form-checkbox
                    v-model="rule.selected"
                    :disabled="rule.disabled || rule.disabledByDeps"
                    @change="changeRule(rule, group)"
                    hide-details
                  >
                    {{ rule.title }}
                  </b-form-checkbox>
                  <div class="ml-2" v-if="rule.disabledByDeps">
                    <b-tooltip bottom>
                      <b-icon slot="activator" size="22" light>mdi-help-circle-outline</b-icon>

                      <div>
                        {{ $t('roles.hints.depends') }}
                        <ul>
                          <li v-for="(depRule, index) in rule.backDeps.filter((r) => r.selected)" :key="index">
                            <span>{{ depRule.label }}</span>
                          </li>
                        </ul>
                      </div>
                    </b-tooltip>
                  </div>
                </li>
              </ul>
            </li>
          </template>
        </ul>

        <b-spinner v-if="isLoading" small />
      </b-container>

      <b-button class="mt-4" @click="save" :disabled="!isSendButtonEnabled || isSending" variant="primary">
        <b-spinner v-if="isSending" small />

        <template v-else>
          {{ $t('Save rules') }}
        </template>
      </b-button>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue';

import api from '@/api/company';

export default Vue.extend({
  name: 'CompanyEditRules',

  props: {
    item: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      error: null,
      isValid: false,
      isLoading: false,
      isSending: false,
      form: {
        name: null,
        ruleGroups: [],
      },
      formRules: {
        name: [(v) => !!v || this.$t('roles.message.required.name')],
      },
      companyRules: null,
      countOfSelected: 0,
      ruleGroups: null,
      groupsByName: new Map(),
      rulesByName: new Map(),
      allRules: [],
    };
  },

  computed: {
    isSendButtonEnabled() {
      const isValid = Boolean(this.countOfSelected && this.isValid);

      return isValid;
    },
  },

  methods: {
    async save() {
      this.error = null;
      this.isSending = true;

      const data = Array.from(this.rulesByName.values())
        .filter((rule) => rule.selected)
        .map((rule) => rule.name);

      try {
        console.log(this.item);
        await api.updateCompanyRules(this.item.external_id, { rules: data });

        this.$router.push({ name: 'company', params: { id: this.item.id.toString() } });

        this.back();
      } catch (err) {
        this.error = err;
      } finally {
        this.isSending = false;
      }
    },

    changeGroup(group) {
      for (const rule of group.rules) {
        const newValue = Boolean(group.selected);
        if (!rule.disabled && rule.selected !== newValue) {
          rule.selected = newValue;
        }
      }

      this.recalcProps();
    },

    changeRule() {
      this.recalcProps();
    },

    recalcRulesProps() {
      let hasAffectedRules = false;

      for (const rule of this.rulesByName.values()) {
        if (rule.selected && rule.deps.length) {
          for (const depRule of rule.deps) {
            if (!depRule.selected) {
              depRule.selected = true;
              hasAffectedRules = true;
            }
          }
        }

        if (rule.backDeps.length) {
          const disabledByDeps = rule.backDeps.some((rule) => rule.selected);

          if (rule.disabledByDeps !== disabledByDeps) {
            rule.disabledByDeps = disabledByDeps;
          }
        }
      }

      if (hasAffectedRules) {
        this.recalcRulesProps();
      }
    },

    recalcGroupProps() {
      let totalSelected = 0;
      for (const group of this.groupsByName.values()) {
        const rulesCounts = {
          selected: 0,
          visibleSelected: 0,
          visibleDisabled: 0,
          disabledByDeps: 0,
          visible: 0,
        };

        for (const rule of group.rules) {
          if (rule.visible) {
            rule.selected && rulesCounts.visibleSelected++;
            rule.disabled && rulesCounts.visibleDisabled++;
            rule.disabledByDeps && rulesCounts.visibleDisabled++;
          }

          if (rule.selected) {
            rulesCounts.selected++;
          }

          if (rule.visible) {
            rulesCounts.visible++;
          }
        }

        const countOfVisibleRules = rulesCounts.visible;
        const groupProps = {
          selected: countOfVisibleRules === rulesCounts.visibleSelected,
          disabled: countOfVisibleRules === rulesCounts.visibleDisabled,
          visible: countOfVisibleRules > 0,
          indeterminate: rulesCounts.visibleSelected > 0 && countOfVisibleRules > rulesCounts.visibleSelected,
        };

        for (const [key, value] of Object.entries(groupProps)) {
          if (group[key] !== value) {
            group[key] = value;
          }
        }

        totalSelected += rulesCounts.selected;
      }

      this.countOfSelected = totalSelected;
    },

    recalcProps() {
      this.recalcRulesProps();
      this.recalcGroupProps();
    },

    async loadRules() {
      this.isLoading = true;

      const companyRole = await api.getCompanyRole(this.item.external_id);
      this.companyRules = companyRole.rules;

      this.allRules = await api.getCompanyAvailableRules(this.item.external_id);

      this.isLoading = false;
    },

    prepareData() {
      let groups = [];

      for (const rule of this.allRules) {
        let group = this.groupsByName.get(rule.group);

        if (!group) {
          group = {
            name: rule.group,
            title: this.$t('roles.' + rule.group + '.title'),
            intermediate: false,
            visible: true,
            disabled: false,
            selected: false,
            rules: [],
          };

          groups.push(group);

          this.groupsByName.set(group.name, group);
        }

        const item = {
          ...rule,
          title: this.$t('roles.' + rule.group + '.' + rule.name.split(' ').join('_')),
          selected: false,
          disabledByDeps: false,
          backDeps: [],
          deps: rule.deps || [],
        };

        group.rules.push(item);

        this.rulesByName.set(item.name, item);
      }

      for (const rule of this.rulesByName.values()) {
        for (const [index, depName] of rule.deps.entries()) {
          const dep = this.rulesByName.get(depName);

          if (dep) {
            rule.deps.splice(index, 1, dep);
            dep.backDeps.push(rule);
          }
        }
      }

      if (this.item) {
        console.log(this.item);
        for (const ruleName of this.companyRules) {
          const rule = this.rulesByName.get(ruleName);

          if (rule) {
            rule.selected = true;
          }
        }
      } else {
        for (const rule of this.rulesByName.values()) {
          rule.selected = rule.default;
        }
      }

      this.recalcProps();

      groups = groups.filter((group) => group.visible);
      groups.sort((a, b) => a.title - b.title);

      for (const group of groups) {
        group.rules = group.rules.filter((rule) => rule.visible);
        group.rules.sort((a, b) => a.title - b.title);
      }

      console.log(this.groupsByName);

      this.ruleGroups = groups;
    },
  },

  async created() {
    if (this.item) {
      this.form.name = this.item.name;
    }

    await this.loadRules();
    this.prepareData();
  },
});
</script>

<style lang="scss">
.roles-form {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .v-input--checkbox {
    vertical-align: top;
  }

  .v-input--selection-controls {
    margin: 0;
    padding: 0;

    .v-input__slot {
      margin: 0;
    }
  }

  .group {
    li {
      margin-top: 16px;
    }
  }

  .rule {
    margin-left: 32px;
  }

  .hint-rule-depends {
  }
}
</style>
