<template>
  <div>
    <b-btn v-b-modal.api>{{ $t('API') }}</b-btn>

    <b-modal id="api" :title="$t('API')" hide-footer @hidden="closeApiDialog">
      <b-table :items="info" :fields="fields" ref="api" striped hover show-empty small sticky-header="400px">
        <template #cell(delete_btn)="row">
          <b-icon @click="deleteClient(row)" icon="archive-fill"></b-icon>
        </template>
      </b-table>

      <b-form v-if="canCreate" @submit.prevent="submitClient">
        <b-row align-v="end" class="border-top pt-3">
          <b-col sm="12">
            <b-form-group
              :label="$t('client_id')"
              label-for="client-id"
              label-cols-sm="2"
              :invalid-feedback="fieldErrors.client_id"
              :state="fieldErrors.client_id || error ? false : null"
            >
              <b-form-input
                id="client-id"
                type="text"
                v-model="clientId"
                :state="fieldErrors.client_id || error ? false : validation"
                required
              />
            </b-form-group>
          </b-col>

          <b-col sm="12">
            <b-form-group :label="$t('Password')" label-for="client-password" label-cols-sm="2">
              <b-input-group>
                <b-form-input
                  id="client-password"
                  type="text"
                  v-model="clientPassword"
                  :state="fieldErrors.client_secret || error ? false : validation"
                  required
                />

                <b-input-group-append>
                  <b-btn size="sm" variant="outline-secondary" @click="suggestPassword">{{ $t('Suggest') }}</b-btn>
                </b-input-group-append>

                <b-form-invalid-feedback :state="fieldErrors.client_secret || error ? false : null">
                  {{ fieldErrors.client_secret }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col sm="12">
            <b-alert v-if="error" show variant="danger">{{ error.message }}</b-alert>
          </b-col>

          <b-col>
            <b-btn @click="closeApiDialog">{{ $t('Close') }}</b-btn>
          </b-col>

          <b-col class="text-right">
            <b-btn type="submit" :progress="progress" variant="info">{{ $t('Add') }}</b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import api from '@/api/clients';
import { collectAllErrors } from '@/api/utils';
import { suggestPassword } from '@/lib/suggest';

export default {
  props: {
    companyId: {
      type: String,
    },
  },

  data() {
    return {
      fields: [
        {
          key: 'created_at',
          label: this.$t('Created'),
          formatter: (value) => value && this.$d(value, 'date'),
          class: 'created-at',
        },
        { key: 'creator.login', label: '' },
        { key: 'client_id', label: 'client_id' },
        { key: 'delete_btn', label: '' },
      ],
      clientId: '',
      clientPassword: '',
      error: '',
      canCreate: false,
      progress: 0,
    };
  },

  computed: {
    fieldErrors() {
      if (this.error && this.error.errors) {
        return collectAllErrors(this.error.errors);
      }
      return {};
    },

    validation() {
      return this.error ? true : null;
    },

    info() {
      return this.showClient;
    },
  },

  methods: {
    async submitClient() {
      try {
        this.progress = 1;
        const info = {
          client_id: this.clientId,
          client_secret: this.clientPassword,
        };

        await api.create(this.companyId, info);
        this.$refs.api.refresh();

        this.clientId = '';
        this.clientPassword = '';
        this.error = false;
      } catch (err) {
        this.error = err;
      } finally {
        this.progress = 0;
      }
    },

    async showClient() {
      try {
        const res = await api.list(this.companyId);

        if (res.canCreate) this.canCreate = true;

        return res.result;
      } catch (err) {
        this.error = err;
      }
    },

    async deleteClient(row) {
      try {
        await api.remove(this.companyId, row.item.client_id);
        this.$refs.api.refresh();
      } catch (err) {
        this.error = err;
      }
    },

    suggestPassword() {
      this.clientPassword = suggestPassword();
    },

    closeApiDialog() {
      this.$bvModal.hide('api');
      this.clientId = '';
      this.clientPassword = '';
      this.error = '';
    },
  },
};
</script>

<style>
.created-at {
  width: 140px;
}
</style>
