import http from './http';

const path = '/usages';

async function getGrouped(companyId, data = {}) {
  const res = await http.get(`${path}/${companyId}/grouped`, {
    params: data,
  });
  return res.data;
}

async function list(data = {}) {
  const res = await http.get(`${path}`, {
    params: data,
  });
  return res.data;
}

export default {
  getGrouped,
  list,
};
