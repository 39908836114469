<template>
  <div>
    <b-row class="my-1" align-v="end">
      <b-col sm="7" md="5" lg="4" xl="3">
        <slot name="filter" />
        <b-form-group :label="$t('Per page')" label-cols="7">
          <b-form-select :options="pageSizeOptions" v-model="pageSize" style="max-width: 120px" />
        </b-form-group>
      </b-col>

      <b-col cols="2" class="pb-3">
        <slot name="additionalFilters" />
      </b-col>

      <b-col sm="12" md="10" xl="5" v-if="hasFilter">
        <b-form-group>
          <b-input-group>
            <b-form-input style="min-width: 240px" v-model="filter" :placeholder="$t('Type to search')" />
            <b-input-group-append>
              <b-btn @click="clearFilter">{{ $t('Clear') }}</b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="2" class="pb-3" v-if="canCreate">
        <slot name="btnCreate" />
      </b-col>
    </b-row>

    <b-table
      striped
      hover
      show-empty
      ref="grid"
      :items="items"
      :fields="fields"
      :current-page="page"
      :per-page="pageSize"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @row-clicked="onRowClick"
      small
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </b-table>

    <div class="row my-1">
      <div class="col-sm-4"></div>

      <div class="col-sm-4">
        <b-pagination :total-rows="totalRows" :per-page="pageSize" v-model="page" align="center" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let firstField;
    if (this.defaultSortField) {
      firstField = this.fields && this.fields.find((field) => field.key === this.defaultSortField);
    } else {
      firstField = this.fields && this.fields.filter((field) => field.sortable)[0];
    }

    return {
      pageSize: 10,
      page: 1,
      filter: null,
      totalRows: 0,
      pageSizeOptions: [
        { text: 10, value: 10 },
        { text: 100, value: 100 },
        { text: 1000, value: 1000 },
      ],
      sortBy: firstField && firstField.key,
      sortDesc: firstField.sortDirection === 'desc',
      canCreate: false,
    };
  },

  props: {
    /**
     * Callback for getting table data, must including rows and total
     */
    callback: {
      type: Function,
      required: true,
    },

    onRowClick: {
      type: Function,
      default: () => {},
    },

    fields: {
      type: Array,
      required: true,
    },

    hasFilter: {
      type: Boolean,
      default: false,
    },

    defaultSortField: {
      type: String,
      default: null,
    },
  },

  methods: {
    async items(ctx) {
      const options = {
        pageSize: this.pageSize,
        limit: this.pageSize,
        page: this.page,
        sort: this.sortBy,
        sortBy: this.sortBy,
        sortDir: this.sortDesc ? 'desc' : 'asc',
        sortDesc: this.sortDesc ? 1 : 0,
        filter: this.filter,
      };
      const result = await this.callback(options);

      if (result.canCreate) this.canCreate = true;

      this.totalRows = result.total;
      return Array.isArray(result) ? result : Array.isArray(result.result) ? result.result : result.rows;
    },

    clearFilter() {
      this.filter = null;
    },

    refresh() {
      this.$refs.grid.refresh();
    },
  },
};
</script>

<style>
.perpage-label {
  min-width: 150px;
}
</style>
