<template>
  <b-container fluid>
    <div class="mb-4">
      <h1>{{ $t('Errors') }}</h1>
    </div>

    <b-table
      show-empty
      stacked="md"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @filtered="onFiltered"
    >
      <template v-slot:cell(mobileDeviceInfo)="data">
        <span v-if="data.value.os">
          {{ data.value.os === 'android' ? 'Android' : 'iOS' }}, {{ data.value.vendor }}, HS{{
            data.value.clientVersion
          }}
        </span>
      </template>

      <template v-slot:cell(actions)="row">
        <b-button
          :disabled="!row.item.company || !row.item.trip_id"
          @click="goToTrip(row.item.company.link, row.item.trip_id)"
        >
          {{ $t('Go on a trip') }}
        </b-button>
      </template>
    </b-table>

    <div>
      <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" align="center" />
    </div>
  </b-container>
</template>

<script>
import api from '@/api';

export default {
  data() {
    return {
      items: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      sortBy: null,
      sortDesc: false,
      filter: null,
    };
  },

  computed: {
    fields() {
      return [
        {
          key: 'date',
          label: this.$t('Date'),
          sortable: true,
          formatter: (value) => this.$d(value, 'date'),
        },
        {
          key: 'company',
          label: this.$t('Company'),
          sortable: true,
          formatter: (value) => (value ? value.name : ''),
        },
        {
          key: 'trip_id',
          label: this.$t('Trip Id'),
          sortable: true,
        },
        {
          key: 'error_code',
          label: this.$t('Error code'),
          sortable: true,
        },
        {
          key: 'error_message',
          label: this.$t('Error message'),
          sortable: true,
          class: 'error-message',
        },
        { key: 'mobileDeviceInfo', label: this.$t('Device') },
        { key: 'actions', label: this.$t('Actions') },
      ];
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      this.items = [];
      const data = await api.errors({ page: this.currentPage, limit: this.perPage, sort: 'date', sortDir: 'desc' });

      if (data.list && data.list.length > 0) {
        this.items = data.list;
      }
      if (data.total) {
        this.totalRows = data.total;
      }
    },

    async approve(invoiceId, button) {
      try {
        this.error = null;
        button.disabled = true;
        await api.invoice.approve(invoiceId);
        await this.fetchData();
      } catch (err) {
        this.error = err;
      } finally {
        button.disabled = false;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    goToTrip(companyLink, tripId) {
      window.open(`${companyLink}trips/${tripId}`);
    },
  },

  watch: {
    async currentPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.fetchData();
      }
    },
  },
};
</script>

<style>
td.error-message {
  max-width: 300px;
  font-size: 1rem;
  word-wrap: break-word;
}
</style>
