import http, { setCSRFToken } from './http';
import store from '@/store';

const state = {
  initialized: false,
  waitInit: undefined,
  loggedIn: false,
};

let initResolve, initReject;
state.waitInit = new Promise((resolve, reject) => {
  initResolve = resolve;
  initReject = reject;
});

function getCookie(name) {
  const match = document.cookie.match(new RegExp('(?:^|;\\s*)' + name + '=([^;]*)'));
  return match ? match[1] : null;
}

async function verify(login, password) {
  const res = await http.post('/login', {
    login,
    password,
  });

  state.loggedIn = true;

  let csrfToken;

  if ((csrfToken = getCookie('csrf-token'))) {
    setCSRFToken(csrfToken);
  }

  await userInfo();
  return res;
}

async function init() {
  if (state.initialized) {
    return;
  }

  let csrfToken;
  if ((csrfToken = getCookie('csrf-token'))) {
    setCSRFToken(csrfToken);
  }

  try {
    await userInfo();
    state.loggedIn = true;
    initResolve();
  } catch (error) {
    state.loggedIn = false;
    if (error.status) {
      if (error.status === 401 || error.status === 403) {
        initResolve();
      } else {
        initReject(error);
      }
    } else if (error.request) {
      initReject(error);
    } else {
      initReject(error);
    }
  }

  state.initialized = true;
}

async function waitInit() {
  await state.waitInit;
}

async function userInfo() {
  const res = await http.get('/userInfo');
  store.commit('auth/setPermissions', res.data.permissions);
  store.commit('auth/setUserLogin', res.data.login);
  store.commit('auth/setUserRole', res.data.role);
  return res.data;
}

function loggedIn() {
  return state.loggedIn;
}

async function logout() {
  const res = await http.post('/logout');

  state.initialized = false;
  state.waitInit = undefined;
  state.loggedIn = false;

  return res.data;
}

export default {
  verify,
  init,
  waitInit,
  userInfo,
  loggedIn,
  logout,
};
