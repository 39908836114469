<template>
  <b-container fluid>
    <div class="mb-4">
      <h1>{{ $t('Logs') }}</h1>
    </div>

    <b-card :title="$t('Logs_instruction.header')" class="mb-2">
      <b-card-text>
        {{ $t('Logs_instruction.subheader') }}

        <ul>
          <li>{{ $t('Logs_instruction.company') }}</li>
          <li>{{ $t('Logs_instruction.account') }}</li>
          <li>{{ $t('Logs_instruction.date') }}</li>
        </ul>
      </b-card-text>
    </b-card>

    <br />

    <b-form @submit.prevent="getLogs" validated>
      <b-form-group label-for="inputCompany" :label="$t('Company')">
        <b-form-input
          id="inputCompany"
          v-model.trim="company"
          type="text"
          class="form-control"
          :required="requiredCompany"
        />
      </b-form-group>

      <b-form-group label-for="inputAccount" :label="$t('Account')">
        <b-form-input
          id="inputAccount"
          v-model.trim="account"
          type="text"
          class="form-control"
          :required="requiredAccount"
        />
      </b-form-group>

      <b-row>
        <b-col cols="4">
          <b-form-group :label="$t('Date')">
            <date-picker v-model="date" :lang="locale" input-class="form-control"></date-picker>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group :label="$t('Storage')">
            <b-form-select v-model="storage" :options="storageOpts" />
          </b-form-group>
        </b-col>

        <b-col cols="4" v-if="!isS3Storage">
          <b-form-group :label="$t('Type')">
            <b-form-select v-model="logType" :options="logTypeOpts" />
          </b-form-group>
        </b-col>
      </b-row>

      <v-submit :progress="progress">{{ $t('Submit') }}</v-submit>
    </b-form>

    <template v-if="logs && logs.length">
      <b-row align-v="end">
        <b-col style="flex-grow: 0">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" style="margin-bottom: 0" />
        </b-col>

        <b-col cols="2" style="font-size: 14px">
          <b-form-group horizontal :label="$t('Per page')" class="mb-0">
            <b-form-select :options="pageSizeOptions" v-model="perPage" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table striped outlined :items="logs" :fields="fields" class="mt-4">
        <template v-slot:cell(mobileDeviceInfo)="data">
          <span v-if="data.value.os">
            {{ data.value.os === 'android' ? 'Android' : 'iOS' }}, {{ data.value.vendor }}, HS{{
              data.value.clientVersion
            }}
          </span>
        </template>

        <template v-slot:cell(log)="data">
          <a :href="data.value" target="_blank">
            {{ data.value ? $t('Link') : $t('No files') }}
          </a>
        </template>

        <template v-slot:cell(tripUrl)="data">
          <a :href="data.value" target="_blank" v-if="data.value">
            {{ $t('Trip') }}
          </a>
        </template>
      </b-table>

      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" />
    </template>

    <div class="mt-4" v-if="logs !== null && !logs.length">
      <h4>{{ $t('No company or account') }}</h4>
    </div>
  </b-container>
</template>

<script>
import 'vue2-datepicker/index.css';

import api from '@/api';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';

export default {
  name: 'Logs',

  metaInfo: {
    title: 'Logs',
  },

  components: { DatePicker },

  data() {
    return {
      company: '',
      account: '',
      date: null,
      logs: null,
      progress: 0,
      currentPage: 1,
      perPage: 25,
      pageSizeOptions: [
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 100, value: 100 },
      ],
      totalRows: 0,
      storageOpts: [
        { value: 'local', text: 'Database' },
        { value: 's3', text: 'Amazon' },
      ],
      storage: 'local',
      logTypeOpts: [
        { value: 'daily', text: this.$t('Daily') },
        { value: 'report', text: this.$t('ReportLogType') },
      ],
      logType: 'report',
    };
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },

    fields() {
      return [
        {
          key: 'createdAt',
          label: this.$t('Date'),
          formatter: (value) => value && this.$d(value, 'date'),
        },
        { key: 'company', label: this.$t('Company') },
        { key: 'account', label: this.$t('Account') },
        { key: 'mobileDeviceInfo', label: this.$t('Device') },
        { key: 'log', label: this.$t('File') },
        { key: 'reportMessage', label: this.$t('Message') },
        { key: 'tripUrl', label: this.$t('Trip') },
      ];
    },

    formatDate() {
      const date = this.date || new Date();
      return format(date, 'YYYY-MM-DD');
    },

    isS3Storage() {
      return this.storage === 's3';
    },

    requiredCompany() {
      return this.isS3Storage || this.logType === 'daily' || this.account !== '';
    },

    requiredAccount() {
      return this.isS3Storage || this.logType === 'daily' || this.company !== '';
    },
  },

  methods: {
    async getLogs() {
      let query = {
        date: this.formatDate,
        storage: this.storage,
        limit: this.perPage,
        page: this.currentPage !== undefined ? this.currentPage : 0,
        sort: 'createdAt',
      };

      if (this.storage === 's3') {
        query = {
          ...query,
          company: this.company,
          account: this.account,
        };
      } else {
        query = {
          ...query,
          type: this.logType,
        };

        if (this.company) query.company = this.company;
        if (this.account) query.account = this.account;
      }

      const data = await api.logs(query);

      this.logs = data.result;
      this.totalRows = data.total;
    },
  },

  watch: {
    currentPage: 'getLogs',
    perPage: 'getLogs',
  },
};
</script>
