import http from './http';

const path = '/logs';

async function get(data) {
  const res = await http.get(path, {
    params: data,
  });

  return res.data;
}

export default get;
