<template>
  <div class="container-fluid">
    <div class="row">
      <main role="main" class="col-auto">
        <div class="mb-4">
          <h1>{{ $t('Dashboard') }}</h1>
        </div>

        <b-row>
          <b-col sm="auto">
            <b-jumbotron>
              <h2>{{ $t('Number of paid slots') }}:</h2>
              <h3>{{ sumPaidSlots }}</h3>
            </b-jumbotron>
          </b-col>

          <b-col sm="auto">
            <b-jumbotron>
              <h2>{{ $t('Number of active accounts') }}:</h2>
              <h3>{{ sumActiveAccounts }}</h3>
            </b-jumbotron>
          </b-col>
        </b-row>
      </main>
    </div>
  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'Dashboard',
  data() {
    return {
      sumPaidSlots: null,
      sumActiveAccounts: null,
    };
  },

  methods: {
    async getDashboard() {
      const data = await api.dashboard();
      this.sumPaidSlots = Number(data.sumPaidSlots);
      this.sumActiveAccounts = Number(data.sumActiveAccounts);
    },
  },
  created() {
    this.getDashboard();
  },
};
</script>
