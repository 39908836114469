import auth from './auth';
import user from './user';
import company from './company';
import invoice from './invoice';
import errors from './errors';
import logs from './logs';
import dashboard from './dashboard';
import usages from './usages';

export default {
  auth,
  user,
  company,
  invoice,
  errors,
  logs,
  dashboard,
  usages,
};

export { auth, user, company, invoice, errors, logs, dashboard, usages };
