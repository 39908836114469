import http from './http';

const path = '/company';
const pathMulti = '/companies';

async function clearData(id, data) {
  const res = await http.post(`${pathMulti}/${id}/clearData`, data);
  return res.data;
}

async function getCompanyPaymentMethods(id) {
  const res = await http.get(`${pathMulti}/${id}/getCompanyPaymentMethods`);
  return res.data.invoice;
}

async function patchCompanyPaymentMethods(id, data) {
  const res = await http.patch(`${pathMulti}/${id}/paymentMethods`, data);
  return res.data;
}

async function getCompanyBillingStatus(id) {
  const res = await http.get(`${pathMulti}/${id}/getCompanyBillingStatus`);
  return res.data;
}

async function patchBillingStatus(id, data) {
  const res = await http.patch(`${pathMulti}/${id}/billingStatus`, data);
  return res.data;
}

async function list(options) {
  const res = await http.get(pathMulti, {
    params: options,
  });
  return res.data;
}

async function getCountOfPaidSms(id) {
  const res = await http.get(`${pathMulti}/${id}/getCountOfPaidSms`);
  return res.data;
}

async function getCountOfPaidTrips(id) {
  const res = await http.get(`${pathMulti}/${id}/getCountOfPaidTrips`);
  return res.data;
}

async function create(data) {
  const res = await http.post(pathMulti, data);
  return res.data;
}

async function get(id) {
  const res = await http.get(`${pathMulti}/${id}`);
  return res.data;
}

async function getCompanyTrips(id, options) {
  const res = await http.get(`${pathMulti}/${id}/trips`, {
    params: options,
  });
  return res.data;
}

async function update(id, data) {
  const res = await http.patch(`${pathMulti}/${id}`, data);
  return res.data;
}

async function remove(id) {
  const res = await http.delete(`${path}/${id}`);
  return res.data;
}

async function adminLogin(id) {
  const res = await http.get(`${pathMulti}/${id}/adminToken`);
  return res.data;
}

async function enableActive(id, data) {
  const res = await http.post(`${pathMulti}/${id}/enableActive`, data);
  return res.data;
}

async function enableTrial(id, days) {
  const res = await http.post(`${pathMulti}/${id}/enableTrial`, days);
  return res.data;
}

async function enableConfidence(id, days) {
  const res = await http.post(`${pathMulti}/${id}/enableConfidence`, days);
  return res.data;
}

async function enableRestricted(id, days) {
  const res = await http.post(`${pathMulti}/${id}/enableRestricted`, days);
  return res.data;
}

async function enableBlocked(id, days) {
  const res = await http.post(`${pathMulti}/${id}/enableBlocked`, days);
  return res.data;
}

async function updateSettings(id, settings) {
  const res = await http.patch(`${pathMulti}/${id}/settings`, settings);
  return res.data;
}

async function forceUpdateSettings(id) {
  const res = await http.post(`${pathMulti}/${id}/forceUpdateSettings`);
  return res.data;
}

async function getSettingsLogs(id, options) {
  const res = await http.get(`${pathMulti}/${id}/settingsLogs`, {
    params: options,
  });
  return res.data;
}

async function getCompanyRole(id) {
  const res = await http.get(`${pathMulti}/${id}/role`);
  return res.data;
}

async function getCompanyAvailableRules(id) {
  const res = await http.get(`${pathMulti}/${id}/availableRules`);
  return res.data;
}

async function updateCompanyRules(id, data) {
  const res = await http.patch(`${pathMulti}/${id}/role/rules`, data);
  return res.data;
}

const BillingStatus = {
  TRIAL: 'TRIAL',
  ACTIVE: 'ACTIVE',
  CONFIDENCE: 'CONFIDENCE',
  RESTRICTED: 'RESTRICTED',
  BLOCKED: 'BLOCKED',
};

export default {
  clearData,
  list,
  create,
  get,
  getCompanyTrips,
  getCountOfPaidSms,
  getCountOfPaidTrips,
  getCompanyPaymentMethods,
  patchCompanyPaymentMethods,
  update,
  remove,
  adminLogin,
  enableConfidence,
  getCompanyBillingStatus,
  enableActive,
  enableTrial,
  enableRestricted,
  enableBlocked,
  updateSettings,
  forceUpdateSettings,
  getSettingsLogs,
  patchBillingStatus,
  getCompanyRole,
  getCompanyAvailableRules,
  updateCompanyRules,
  BillingStatus,
};

export { BillingStatus };
