<template>
  <div></div>
</template>

<script>
import auth from '@/api/auth';

export default {
  name: 'Logout',

  async created() {
    try {
      await auth.logout();
      this.$router.replace('/login');
    } catch (err) {
      this.$router.push('/');
    }
  },
};
</script>
