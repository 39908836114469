<template>
  <div>
    <b-row>
      <b-col sm="6">
        <b>{{ $t('Current status') }}:</b>
      </b-col>
      <b-col sm="6">{{ $t(`BillingStatus.${currentBillingStatus}`) }}</b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <b>{{ $t('Number of active accounts') }}:</b>
      </b-col>
      <b-col sm="6">{{ billingInfo.active_accounts }}</b-col>
    </b-row>
    <b-row v-if="currentBillingStatus === 'ACTIVE'">
      <b-col sm="6">
        <b>{{ $t('Number of paid slots') }}:</b>
      </b-col>
      <b-col sm="6">{{ paidSlots }}</b-col>
    </b-row>
    <b-row v-if="currentBillingDateEnds">
      <b-col sm="6">
        <b>{{ $t('End date') }}:</b>
      </b-col>
      <b-col sm="6">{{ $d(currentBillingDateEnds, 'dateonly') }}</b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'CompanyBillingInfo',

  props: {
    billingInfo: {
      type: Object,
      default: () => ({}),
    },
    paidSlots: {
      type: Number,
    },
    currentBillingDateEnds: {
      type: String,
    },
    currentBillingStatus: {
      type: String,
    },
  },
};
</script>
