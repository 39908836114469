const passwordChars = {
  numbers: '123456789',
  lowers: 'abcdefghijklmnpqrstuvwxyz',
  uppers: 'ABCDEFGHIJKLMNPQRSTUVWXYZ',
};

function selectChars(passwordCharacter, length) {
  return passwordCharacter
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('')
    .substr(0, length);
}

function suggestPassword() {
  const number = selectChars(passwordChars.numbers, 1);
  const lowers = selectChars(passwordChars.lowers, 6);
  const uppers = selectChars(passwordChars.uppers, 1);

  return selectChars(number + lowers + uppers);
}

export { suggestPassword };
