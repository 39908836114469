import http from './http';

const path = '/info';

async function getInfo() {
  const res = await http.get(path);
  return res.data;
}

export default {
  getInfo,
};
