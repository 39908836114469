<template>
  <b-container>
    <b-alert v-if="error" class="mt-3" show variant="danger">
      {{ error.message }}
    </b-alert>

    <b-alert class="my-3" variant="warning" :show="manualPriceInput">
      {{ $t('Price not Calculated') }}
    </b-alert>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="invoice_number">{{ $t('invoice_number') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="invoice_number" v-model="subscription.invoice_number" type="text" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="invoice_date">{{ $t('invoice_date') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="invoice_date" v-model="subscription.invoice_date" type="date" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="company_name">{{ $t('company_name') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="company_name" v-model="subscription.company_name" type="text" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="itn">{{ $t('itn') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="itn" v-model="subscription.itn" type="number" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="kpp">{{ $t('kpp') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="kpp" v-model="subscription.kpp" type="number" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="legal_address">{{ $t('legal_address') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="legal_address" v-model="subscription.legal_address" type="text" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="slots">{{ $t('slots') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="slots" v-model="subscription.slots" type="number" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="months">{{ $t('month') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="months" v-model="subscription.month" type="number" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="price">{{ $t('price_per_slot') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-input id="price" v-model="subscription.price" type="number" required />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="5">
        <label for="service">{{ $t('service') }}:</label>
      </b-col>

      <b-col sm="7">
        <b-form-textarea rows="2" max-rows="3" id="service" v-model="subscription.service" type="text" required />
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col sm="8">
        <b-button :disabled="!validateToGetPrice" variant="info" @click="calculatePriceFromPlan">
          {{ $t('Calculate price') }}
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="4">
        <v-submit class="mt-3" :progress="progress" :disabled="!validate" block @click="createInvoice">
          {{ $t('Submit') }}
        </v-submit>
      </b-col>

      <b-col sm="7">
        <b-btn variant="success" class="mt-3" v-if="docLink" block :href="docLink" download>
          {{ $t('Download Invoice') }}
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import invoice from '@/api/invoice';
import company from '@/api/company';

export default {
  props: {
    id: {
      type: String,
    },
  },

  data() {
    return {
      error: null,
      docLink: null,
      progress: 0,
      manualPriceInput: null,
      standartInvoiceNumber: null,
      subscription: {
        type: 'SUBSCRIPTION',
        invoice_number: null,
        invoice_date: null,
        company_name: null,
        itn: null,
        kpp: null,
        legal_address: null,
        slots: null,
        month: null,
        price: null,
        service: null,
      },
    };
  },

  computed: {
    validateToGetPrice() {
      return this.subscription.month && this.subscription.slots;
    },

    validate() {
      for (const field in this.subscription) {
        if (!this.subscription[field]) return false;
      }
      return this.subscription.slots > 0 && this.subscription.month > 0 && this.subscription.price >= 0;
    },
  },

  async created() {
    await this.getStandartInvoiceNumber();

    const paymentMethods = await company.getCompanyPaymentMethods(this.id);
    for (const field in paymentMethods) {
      if (field in this.subscription && paymentMethods[field] !== undefined) {
        this.subscription[field] = paymentMethods[field];
      }
    }
  },

  methods: {
    async calculatePriceFromPlan() {
      try {
        if (this.subscription.slots && this.subscription.month) {
          this.manualPriceInput = false;
          const data = await invoice.calculatePriceFromPlan({
            companyId: this.id,
            slots: this.subscription.slots,
            month: this.subscription.month,
            currency: 'RUB',
          });
          if (data.cost_per_slot_by_month) {
            this.manualPriceInput = false;
            this.subscription.price = data.cost_per_slot_by_month;
          } else {
            this.manualPriceInput = true;
          }
        }
      } catch (e) {
        this.error = e.message;
      } finally {
        this.formService();
      }
    },

    async formService() {
      try {
        const data = await invoice.formService({
          companyId: this.id,
          manualPrice: this.manualPriceInput,
          slots: this.subscription.slots,
          month: this.subscription.month,
          currency: 'RUB',
        });
        this.subscription.service = data.service;
      } catch (e) {
        this.error = e.message;
      }
    },

    async createInvoice() {
      try {
        this.docLink = null;
        this.manualPriceInput = false;
        this.progress = 1;
        this.error = null;

        const data = await invoice.createInvoiceAdmin({
          companyId: this.id,
          country: 'ru',
          manualPrice: this.manualPriceInput,
          ...this.subscription,
        });

        this.docLink = data.url;
        this.total_cost = data.total_cost;
        this.toast(`${this.$t('Invoice succesfully created')}. ${this.$t('total_cost')}: ${data.total_cost}`);
      } catch (error) {
        if (error.message === 'Billing Error') {
          error.message = this.$t('Billing info not found');
        }
        this.error = error;
      }
      this.progress = 0;
      await this.getStandartInvoiceNumber();
    },

    async getStandartInvoiceNumber() {
      const data = await invoice.getStandartInvoiceNumber(this.id);
      this.subscription.invoice_number = data.invoiceNumber;
      this.standartInvoiceNumber = data.invoiceNumber;
    },
  },
};
</script>
