<template>
  <b-nav :vertical="true" :pills="true">
    <template v-for="item in permMenu">
      <b-nav :vertical="true" v-if="item.items" :key="item.href">
        <b-nav-item v-for="subItem in item.items" :key="subItem.href" :to="subItem.href" :exact="true">{{
          $t(subItem.title)
        }}</b-nav-item>
      </b-nav>
      <h6 v-else-if="item.header" :key="item.href">{{ $t(item.header) }}</h6>
      <hr v-else-if="item.divider" style="width: 100%" :key="item.href" />
      <b-nav-item v-else :to="item.href" :exact="true" :key="item.href">{{ $t(item.title) }}</b-nav-item>
    </template>
  </b-nav>
</template>

<script>
import { mapState } from 'vuex';
import menu from '@/menu';

export default {
  name: 'MainNav',

  data() {
    return {
      menu,
    };
  },

  computed: {
    ...mapState('auth', ['permissions']),

    permMenu() {
      return menu.filter((item) => this.hasPermissions(item));
    },
  },

  methods: {
    hasPermissions(item) {
      if (item.permission) {
        if (Array.isArray(item.permission)) {
          return item.permission.some((p) => this.permissions[p]);
        } else {
          return this.permissions[item.permission];
        }
      }

      return true;
    },
  },
};
</script>
