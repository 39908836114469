import http from './http';

const path = '/errors';

async function get(options) {
  const res = await http.get(path, {
    params: options,
  });

  return res.data;
}

export default get;
