import http from './http';

const path = '/invoices';

async function list() {
  const res = await http.get(path, {});
  return res.data;
}

async function approve(invoiceId) {
  await http.post(`${path}/${invoiceId}/approve`, {});
}

async function approveCustomInvoice(invoiceId) {
  await http.post(`${path}/${invoiceId}/approveCustomInvoice`, {});
}

async function create(data) {
  const res = await http.post(path, data);
  return res.data;
}

async function createInvoiceAdmin(data) {
  const res = await http.post(`${path}/createInvoiceAdmin`, data, { timeout: 30000 });
  return res.data;
}

async function getStandartInvoiceNumber(companyId) {
  const res = await http.get(`${path}/${companyId}/getStandartInvoiceNumber`);
  return res.data;
}

async function calculatePriceFromPlan(data) {
  try {
    const res = await http.post(`${path}/calculatePriceFromPlan`, data);
    return res.data;
  } catch (e) {
    if (e.data.code === 'IndividualPlanNotFound') {
      const res = {};
      res.cost_per_slot_by_month = null;
      return res;
    }
  }
}

async function formService(data) {
  const res = await http.post(`${path}/formService`, data);
  return res.data;
}

async function getSmsPlan() {
  const res = await http.get(`${path}/getSmsPlan`, {});
  return res.data;
}

export default {
  list,
  approve,
  approveCustomInvoice,
  create,
  createInvoiceAdmin,
  getStandartInvoiceNumber,
  calculatePriceFromPlan,
  formService,
  getSmsPlan,
};
