function collectAllErrors(errors) {
  const errorMap = {};
  for (const [key, errorDetails] of Object.entries(errors)) {
    if (Array.isArray(errorDetails)) {
      errorMap[key] = errorDetails.map((individualError) => individualError.message).join(', ');
    }
  }
  return errorMap;
}

export { collectAllErrors };
