const getDefaultState = () => {
  return {
    userId: null,
    userLogin: null,
    role: null,
    permissions: {},
    loggedIn: false,
    initialized: false,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setPermission(state, { permissionName, data }) {
      state.permissions[permissionName] = data;
    },
    setPermissions(state, data) {
      state.permissions = data;
    },
    setUserId(state, data) {
      state.userId = data;
    },
    setLoggedIn(state, data) {
      state.loggedIn = data;
    },
    setInitialized(state, data) {
      state.initialized = data;
    },
    setUserLogin(state, data) {
      state.userLogin = data;
    },
    setUserRole(state, data) {
      state.role = data;
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    userLogin: (state) => {
      return state.userLogin;
    },
  },
};
