export default [
  { href: '/', title: 'Dashboard' },
  { href: '/users', title: 'Users' },
  { href: '/companies', title: 'Companies' },
  { href: '/invoices', title: 'Invoices', permission: 'accessFinancialInfo' },
  { href: '/logs', title: 'Logs', permission: 'accessLogs' },
  { href: '/requests', title: 'Requests', permission: 'accessTrialCompanies' },
  { href: '/errors', title: 'Errors' },
  { href: '/info', title: 'Info' },
  { href: '/distributions', title: 'Distributions' },
  { divider: true },
  // { href: '/login', title: 'Logout' }
  { href: '/logout', title: 'Logout' },
];
