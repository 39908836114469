import http from './http';

const path = '/dashboard';

async function get() {
  const res = await http.get(path);

  return res.data;
}

export default get;
