<template>
  <div>
    <b-btn v-b-modal.cleaner>{{ $t('Clear') }}</b-btn>

    <b-modal id="cleaner" :title="$t('Clear')" hide-footer @hidden="closeDialog">
      <b-form ref="api" @submit.prevent="submitClient">
        <b-row align-v="end">
          <b-col sm="12">
            <b-form-group :label="$t('Checkboxes.Title')" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-group"
                v-model="selectedItems"
                :aria-describedby="ariaDescribedby"
                name="items"
              >
                <b-form-checkbox v-for="item in items" :key="item.value" :value="item.value">
                  {{ $t(`Checkboxes.${item.text}`) }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>

          <b-col sm="12">
            <b-alert v-if="error" show variant="danger">{{ error.message }}</b-alert>
          </b-col>

          <b-col>
            <b-btn @click="closeDialog">{{ $t('Close') }}</b-btn>
          </b-col>

          <b-col class="text-right">
            <b-btn type="submit" :disabled="!selectedItems.length || !!progress" :progress="progress" variant="info">
              {{ $t('Clear') }}
            </b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import api from '@/api/company';
import { collectAllErrors } from '@/api/utils';
import { suggestPassword } from '@/lib/suggest';

export default {
  name: 'CleanCheckBoxDialog',
  props: {
    companyId: {
      type: String,
    },
  },
  data() {
    return {
      items: [
        { text: 'Accounts', value: 'accounts' },
        { text: 'Branches', value: 'branches' },
        { text: 'Trips', value: 'trips' },
        { text: 'Contractors', value: 'contractors' },
        { text: 'Orders', value: 'orders' },
        { text: 'FavoritesPoint', value: 'favorites_point' },
        { text: 'Devices', value: 'devices' },
        { text: 'PointFunctions', value: 'point_functions' },
        { text: 'Shifts', value: 'shifts' },
        { text: 'Tags', value: 'tags' },
        { text: 'Transport', value: 'transports' },
        { text: 'Planning', value: 'veeroute_logs' },
        { text: 'Zones', value: 'zones' },
      ],
      progress: 0,
      selectedItems: [],
      error: '',
    };
  },

  computed: {
    fieldErrors() {
      if (this.error && this.error.errors) {
        return collectAllErrors(this.error.errors);
      }
      return {};
    },
  },

  methods: {
    async submitClient() {
      try {
        this.error = false;
        this.progress = 1;
        await api.clearData(this.companyId, this.selectedItems);
        this.toast(this.$t('Data cleared'));
        this.closeDialog();
        this.selectedItems = [];
      } catch (err) {
        this.error = err;
      } finally {
        this.progress = 0;
      }
    },

    suggestPassword() {
      this.clientPassword = suggestPassword();
    },

    closeDialog() {
      this.$bvModal.hide('cleaner');
      this.selectedItems = [];
      this.error = '';
    },
  },
};
</script>

<style>
.created-at {
  width: 140px;
}
</style>
