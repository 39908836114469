import http from './http';

const path = '/requests';

async function list(options) {
  const res = await http.get(path, {
    params: options,
  });
  return res.data;
}

export default {
  list,
};
