<template>
  <b-container fluid>
    <div class="mb-4">
      <h1>{{ $t('Distribution usage') }}</h1>
    </div>

    <base-grid :callback="fetchData" :fields="fields" ref="grid">
      <template slot="filter">
        <b-form-group horizontal label-cols="3" content-cols="4" label-class="perpage-label" :label="$t('Period')">
          <b-form-select :options="periodOptions" v-model="period" style="max-width: 150px" />
        </b-form-group>
        <b-form-group horizontal label-cols="3" content-cols="4" label-class="perpage-label" :label="$t('Environment')">
          <b-form-select :options="isTestingOptions" v-model="isTesting" style="max-width: 150px" />
        </b-form-group>
      </template>

      <template v-slot:cell(account)="{ item }">
        <div v-if="item.account">
          {{ item.account.fullName }}
          <em>
            (<span v-if="item.adminType && item.adminType === 'adminLogin' && item.user">{{ item.user.login }}</span>
            <span v-if="item.adminType && item.adminType === 'debugLogin'">sudo</span>)
          </em>
        </div>

        <div v-else-if="item.user">
          <em>{{ item.user.login }}</em>
        </div>
      </template>

      <template v-slot:cell(resourceNumber)="{ item }">
        <div v-if="item.transportCount">
          {{ Number(item.resourceNumber) + item.transportCount }}
        </div>

        <div v-else>
          {{ item.resourceNumber }}
        </div>
      </template>
    </base-grid>
  </b-container>
</template>

<script>
import api from '@/api';
import baseGridComponent from '@/components/BaseGrid';

export default {
  components: {
    baseGrid: baseGridComponent,
  },

  data() {
    return {
      period: 'month',
      isTesting: '',
    };
  },

  computed: {
    fields() {
      return [
        {
          key: 'date',
          label: this.$t('Date'),
          sortable: true,
          sortDirection: 'desc',
          formatter: (value) =>
            this.period === 'quarter' ? value.replace(' ', ' Q') : this.$d(value, this.dateFormat),
        },
        { key: 'moduleName', label: this.$t('Module name'), sortable: false },
        { key: 'resourceName', label: this.$t('Resource name'), sortable: false },
        { key: 'resourceNumber', label: this.$t('Usage count'), sortable: false },
        { key: 'isTrafficJams', label: this.$t('TrafficJams '), sortable: false },
        { key: 'isTesting', label: this.$t('Testing'), sortable: false },
      ];
    },

    dateFormat() {
      switch (this.period) {
        case 'year':
          return 'YYYY';
        case 'quarter':
          return '';
        case 'month':
          return 'MMM YYYY';
        case 'day':
        default:
          return 'standard';
      }
    },

    periodOptions() {
      return [
        { text: this.$t('Day'), value: 'day' },
        { text: this.$t('Month'), value: 'month' },
        { text: this.$t('Quarter'), value: 'quarter' },
        { text: this.$t('Year'), value: 'year' },
      ];
    },

    isTestingOptions() {
      return [
        { text: this.$t('All'), value: '' },
        { text: this.$t('Testing'), value: true },
        { text: this.$t('Production'), value: false },
      ];
    },
  },

  methods: {
    async fetchData(options) {
      return api.usages.getGrouped(this.$route.params.id, {
        isTesting: this.isTesting !== '' ? this.isTesting : undefined,
        period: this.period,
        limit: options.pageSize,
        page: options.page,
        sort: options.sort,
        sortDir: options.sortDir,
      });
    },
  },

  watch: {
    isTesting() {
      this.$refs.grid.refresh();
    },

    period() {
      this.$refs.grid.refresh();
    },
  },
};
</script>
