<template>
  <div class="company-role">
    <h3 class="mb-3">{{ $t('Rules') }}</h3>

    <b-alert v-if="error" show variant="danger" class="mb-0">
      {{ error }}
    </b-alert>

    <div v-if="!loading && rules.length">
      <div class="rules mb-4">
        <b-button v-for="rule in rules" :key="rule.value" variant="outline-secondary" size="sm" disabled>
          {{ rule.title }}
        </b-button>
      </div>

      <b-button variant="primary" :to="{ name: 'companyRules', params: { id: company.id } }">
        {{ $t('Edit rules') }}
      </b-button>
    </div>

    <b-spinner v-if="loading" variant="primary" />
  </div>
</template>

<script>
import api from '@/api/company';

export default {
  name: 'CompanyRole',

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      rules: [],
      error: null,
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.loading = true;
      this.error = null;

      try {
        const availableRules = await api.getCompanyAvailableRules(this.company.external_id);
        const { rules } = await api.getCompanyRole(this.company.external_id);

        const data = [];
        if (Array.isArray(rules)) {
          for (const rule of rules) {
            const found = availableRules.find((item) => item.name === rule);

            if (found) {
              data.push({
                title: this.$t('roles.' + found.group + '.' + found.name.split(' ').join('_')),
                value: rule,
              });
            }
          }
        }
        this.rules = data;

        this.loading = false;
      } catch (error) {
        this.error = error;

        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.company-role {
  .rules {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .btn {
      opacity: 1 !important;
    }
  }
}
</style>
