<template>
  <div>
    <div class="mb-4">
      <h1>{{ $t('Requests') }}</h1>
    </div>

    <b-container fluid>
      <base-grid :callback="fetchRequests" :fields="fields" default-sort-field="createdAt" />
    </b-container>
  </div>
</template>

<script>
import api from '@/api/request';
import baseGridComponent from '@/components/BaseGrid';

export default {
  name: 'Request',

  metaInfo: {
    title: 'Request',
  },

  computed: {
    fields() {
      return [
        { key: 'id', label: this.$t('ID'), sortable: true, sortDirection: 'desc' },
        { key: 'name', label: this.$t('Company name'), sortable: true },
        { key: 'domain', label: this.$t('Domain'), sortable: false },
        {
          key: 'createdAt',
          label: this.$t('Created'),
          sortable: true,
          sortDirection: 'desc',
          formatter: (value) => this.$d(value, 'short'),
        },
        { key: 'fullName', label: this.$t('Name'), sortable: false },
        { key: 'email', label: 'Email', sortable: false },
        { key: 'phone', label: this.$t('Phone'), sortable: false },
        { key: 'country', label: this.$t('Country'), sortable: false },
        { key: 'size', label: this.$t('Number of employees'), sortable: false },
        {
          key: 'status',
          label: this.$t('Status'),
          sortable: true,
          formatter: (value) => this.mapStatus(value),
        },
      ];
    },
  },

  components: {
    baseGrid: baseGridComponent,
  },

  methods: {
    async fetchRequests(options) {
      return api.list(options);
    },

    mapStatus(status) {
      return ['NEW', 'CONFIRMED', 'ACCEPTED', 'REJECTED'][status];
    },
  },
};
</script>
