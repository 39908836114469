<template>
  <b-container fluid>
    <b-breadcrumb :items="breadcrumbs" />

    <b-alert v-if="error" :show="true" variant="danger">{{ error.message }}</b-alert>

    <b-row>
      <b-col sm="6">
        <CompanyBasicInfo
          v-if="companyInfo"
          :info="companyInfo"
          :sms-count="countOfPaidSms"
          :trips-count="countOfPaidTrips"
        />

        <CompanyBillingInfo
          v-if="billingInfo"
          :billing-info="billingInfo"
          :current-billing-date-ends="currentBillingDateEnds"
          :current-billing-status="currentBillingStatus"
          :paid-slots="paid_slots"
        />
      </b-col>

      <b-col class="d-flex align-items-end flex-row">
        <b-btn v-if="companyInfo" :to="`/companies/${companyInfo.id}/edit`" class="mr-5" variant="primary">
          {{ $t('Edit') }}
        </b-btn>

        <CleanCheckBoxDialog :companyId="companyId" />
      </b-col>
    </b-row>

    <b-card class="my-4" v-if="permissions.accessFinancialInfo && companyInfo">
      <b-alert v-model="changingStatusSuccess" variant="success" dismissible>
        {{ $t('Status change', [$t(`BillingStatus.${currentBillingStatus}`)]) }}
      </b-alert>

      <b-alert v-model="emptyStatus" variant="danger">
        {{ $t('Select company status') }}
      </b-alert>

      <b-alert v-model="emptyDate" variant="danger">
        {{ $t('Enter end date') }}
      </b-alert>

      <b-alert v-model="emptySlots" variant="danger">
        {{ $t('Enter the number of slots') }}
      </b-alert>

      <b-row class="mb-4">
        <b-col sm="3" class="mb-1">
          <label for="select-status">{{ $t('Select company status') }}:</label>
          <b-form-select id="select-status" v-model="newStatus" :options="statuses" />
        </b-col>

        <b-col sm="3">
          <label for="new-billing-date-ends">{{ $t('Enter end date') }}:</label>
          <b-form-input id="new-billing-date-ends" type="date" v-model="newBillingDateEnds" />
        </b-col>

        <b-col sm="3" v-if="showSlotsInput" class="mb-1">
          <label for="new-slots-amount">{{ $t('Enter the number of slots') }}:</label>
          <b-form-input id="new-slots-amount" type="number" v-model="newSlotsAmount" />
        </b-col>

        <b-col sm="2" style="display: flex; align-items: flex-end" class="mb-1">
          <b-button @click="changeStatus" variant="primary">{{ $t('Submit') }}</b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="permissions.accessFinancialInfo && companyInfo">
      <b-alert v-model="changeBillingStatusSuccess" variant="success" dismissible>
        {{ $t('Billing info has been changed') }}
      </b-alert>

      <b-row>
        <b-col sm="4">
          <b-form-group horizontal label-cols="8" content-cols="4" :label="$t('Enter paid number of devices:')">
            <b-form-input v-model="paidDevices" type="number" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="4">
          <b-form-group horizontal label-cols="8" content-cols="4" :label="$t('Enter paid number of trips:')">
            <b-form-input v-model="paidTrips" type="number" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col sm="4">
          <b-form-group
            horizontal
            label-cols="8"
            content-cols="4"
            :label="$t('Enter paid number of distinct points:')"
            class="mb-0"
          >
            <b-form-input v-model="paidDistPoints" type="number" :disabled="companyInfo.settings.isVeeRouteTest" />
          </b-form-group>
        </b-col>

        <b-col sm="8">
          <b-button :to="{ name: 'distributionUsage', params: { id: companyInfo.external_id } }" class="ml-2">
            {{ $t('Show distribution usage') }}
          </b-button>
        </b-col>

        <b-col sm="8" v-if="companyInfo.settings.isVeeRouteTest">
          <span style="color: grey">{{ $t('VeeRoute test environment has no limits') }}</span>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="4">
          <b-button @click="updateBillingStatus" variant="primary">{{ $t('Submit') }}</b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-row class="mt-4" v-if="canAdminLogin">
      <b-col sm="12">
        <b-button variant="primary" @click.stop="superAdminLogin()">{{ $t('Login as superadmin') }}</b-button>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="auto" class="mb-2">
        <create-invoice :id="$route.params.id" :country="companyInfo && companyInfo.country" />
      </b-col>

      <b-col class="mb-2">
        <create-api-dialog v-if="isAdmin" :companyId="companyId" />
      </b-col>
    </b-row>

    <b-card class="mt-4" v-if="companyInfo && companyInfo.settings">
      <label for="select-trip-mode">{{ $t('CompanySettings.tripMode') }}:</label><br />
      <b-form-select
        class="mb-3 w-25"
        id="select-trip-mode"
        v-model="companyInfo.settings.tripMode"
        :options="tripModes"
      />

      <br />

      <label for="select-routing-provider">{{ $t('CompanySettings.routingProviderName') }}:</label><br />
      <b-form-select
        class="mb-3 w-25"
        id="select-routing-provider"
        v-model="companyInfo.settings.routingProviderName"
        :options="routingProviders"
      />

      <br />

      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-checkbox v-model="companyInfo.settings.autoLinkingFavorites.enable">
          {{ $t(`CompanySettings.autoAssignFavoritesOnMobile`) }}
        </b-form-checkbox>

        <label class="mt-3">{{ $t(`CompanySettings.autoAssignFavoritesOnMobilePointTypes`) }}:</label>
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="companyInfo.settings.autoLinkingFavorites.pointTypes"
          :options="optionsPointTypesToAssignFavorites"
          :aria-describedby="ariaDescribedby"
          :disabled="!companyInfo.settings.autoLinkingFavorites.enable"
          name="flavour-1"
        />
      </b-form-group>

      <b-form-checkbox v-for="key in settingsCheckboxes" :key="key" v-model="companyInfo.settings[key]">
        {{ $t(`CompanySettings.${key}`) }} <span style="color: grey">{{ key }}</span>
      </b-form-checkbox>

      <b-form-checkbox
        v-for="key in tripPermissionsCheckboxes"
        :key="key"
        v-model="companyInfo.settings.tripPermissions[key]"
      >
        {{ $t(`CompanySettings.tripPermissions.${key}`) }} <span style="color: grey">{{ key }}</span>
      </b-form-checkbox>

      <b-form-checkbox class="mt-3 mb-2" v-model="companyInfo.settings.auto_cancel_trips.enabled">
        {{ $t(`CompanySettings.autoCancelTrip`) }}
      </b-form-checkbox>
      <label>{{ $t(`CompanySettings.autoCancelTripTime`) }}:</label>
      <b-form-timepicker class="cancel-trip-timepicker col-md-4" value="00:00:00" disabled />

      <b-form-checkbox class="mt-3 mb-2" v-model="companyInfo.settings.serviceCenterContact.callFromMobile">
        {{ $t(`CompanySettings.serviceCenterContactEnabled`) }}
      </b-form-checkbox>
      <b-form-input
        class="col-md-4"
        v-model="companyInfo.settings.serviceCenterContact.number"
        :placeholder="$t(`CompanySettings.serviceCenterContactNumber`)"
      />

      <label class="mt-3">{{ $t(`CompanySettings.maxAllowedAccuracy`) }}:</label>
      <b-form-input class="col-md-4" type="number" :number="true" v-model="companyInfo.settings.maxAllowedAccuracy" />

      <label class="mt-3">{{ $t(`CompanySettings.checkinRadius`) }}:</label>
      <b-form-input class="col-md-4" type="number" :number="true" v-model="companyInfo.settings.pointRadius.checkin" />

      <label class="mt-3">{{ $t(`CompanySettings.checkoutRadius`) }}:</label>
      <b-form-input class="col-md-4" type="number" :number="true" v-model="companyInfo.settings.pointRadius.checkout" />

      <b-card class="mt-3">
        <b-row>
          <label class="mt-2">{{ $t(`CompanySettings.locationUpdateInterval`) }}:</label>
          <b-form-input
            class="col-md-4 ml-3"
            type="number"
            :number="true"
            v-model="companyInfo.settings.clientCustomSettings.locationUpdateInterval"
          />
        </b-row>

        <b-row>
          <label class="mt-2">{{ $t(`CompanySettings.locationFastestInterval`) }}:</label>
          <b-form-input
            class="col-md-4 ml-3"
            type="number"
            :number="true"
            v-model="companyInfo.settings.clientCustomSettings.locationFastestInterval"
          />
        </b-row>

        <b-row>
          <label class="mt-2">{{ $t(`CompanySettings.locationMaxWaitTime`) }}:</label>
          <b-form-input
            class="col-md-4 ml-3"
            type="number"
            :number="true"
            v-model="companyInfo.settings.clientCustomSettings.locationMaxWaitTime"
          />
        </b-row>

        <b-row>
          <label class="mt-2">{{ $t(`CompanySettings.locationMinDistance`) }}:</label>
          <b-form-input
            class="col-md-4 ml-3"
            type="number"
            :number="true"
            v-model="companyInfo.settings.clientCustomSettings.locationMinDistance"
          />
        </b-row>

        <b-row>
          <label class="mt-2">{{ $t(`CompanySettings.periodicRequestsInterval`) }}:</label>
          <b-form-input
            class="col-md-4 ml-3"
            type="number"
            :number="true"
            v-model="companyInfo.settings.clientCustomSettings.periodicRequestsInterval"
          />
        </b-row>
      </b-card>

      <b-form-input
        class="mt-3"
        v-model="companyInfo.settings.loginFillFormLink"
        :placeholder="$t(`CompanySettings.loginFillFormLink`)"
      />

      <b-form-input
        class="mt-3"
        v-model="companyInfo.settings.logoutFillFormLink"
        :placeholder="$t(`CompanySettings.logoutFillFormLink`)"
      />

      <b-alert v-model="errorChangeSettings" variant="danger" class="mt-4">
        {{ $t('errorChangeSettings') }}
      </b-alert>
      <b-alert v-model="changeSettingsSuccess" variant="success" class="mt-4" dismissible>
        {{ $t('Settings changed') }}
      </b-alert>

      <div class="d-flex flex-wrap gap-3 mt-4">
        <b-button @click="updateSettings" variant="primary">{{ $t('Submit') }}</b-button>
        <b-button :to="{ name: 'companySettingsLogs', params: { id: companyInfo.external_id } }">
          {{ $t('Show settings logs') }}
        </b-button>
        <b-button @click="forceUpdateSettings">{{ $t('Force update settings') }}</b-button>
      </div>
    </b-card>

    <b-card class="mt-4" v-if="companyInfo">
      <company-role :company="companyInfo" />
    </b-card>

    <template v-if="permissions.accessCompanyTrips">
      <BaseGrid class="mt-4" :callback="fetchCompanyTrips" :fields="fields" />
    </template>
  </b-container>
</template>

<script>
/* global $ */
import { mapState } from 'vuex';
import moment from 'moment';

import companyApi from '@/api/company';

import CompanyBasicInfo from '@/components/CompanyBasicInfo';
import CompanyBillingInfo from '@/components/CompanyBillingInfo';
import CompanyRole from '@/components/CompanyRole';
import CreateInvoice from '@/components/CreateInvoiceDialog';
import CreateApiDialog from '@/components/CreateApiDialog';
import BaseGrid from '@/components/BaseGrid';
import CleanCheckBoxDialog from '@/components/CleanCheckBoxDialog';

export default {
  name: 'CompanyDetails',

  metaInfo: {
    title: 'Companies',
  },

  components: {
    CompanyBasicInfo,
    CompanyBillingInfo,
    CompanyRole,
    BaseGrid,
    CreateInvoice,
    CreateApiDialog,
    CleanCheckBoxDialog,
  },

  data() {
    return {
      progress: 0,
      error: null,
      changingStatusSuccess: null,
      changeSettingsSuccess: null,
      changeBillingStatusSuccess: false,
      emptyStatus: false,
      emptyDate: false,
      emptySlots: false,
      errorChangeSettings: false,
      companyInfo: null,
      companyId: null,
      canAdminLogin: false,
      billingInfo: null,
      billingInfoPayment: null,
      confidenceDays: null,
      countOfPaidSms: 0,
      countOfPaidTrips: 0,
      currentBillingStatus: null,
      newStatus: null,
      currentBillingDateEnds: null,
      newBillingDateEnds: null,
      paid_slots: null,
      paidDevices: null,
      paidTrips: null,
      paidDistPoints: null,
      newSlotsAmount: null,
      tripModes: [
        { value: 'auto', text: 'Auto' },
        { value: 'manual', text: 'Manual' },
      ],
      routingProviders: [
        { value: null, text: 'Default (TomTom)' },
        { value: 'TomTom', text: 'TomTom' },
        { value: 'Here', text: 'Here' },
      ],
      autoLinkingFavorites: {
        enable: false,
        pointTypes: [],
      },
      serviceCenterContact: {
        callFromMobile: false,
        number: '',
      },
      auto_cancel_trips: {
        enabled: false,
        trigger: 'timeAt',
        timeAt: '23:59:59',
        inactiveTimeout: 0,
      },
      optionsPointTypesToAssignFavorites: [
        { text: 'Start', value: 'start' },
        { text: 'Not Scheduled', value: 'notScheduled' },
        { text: 'Scheduled', value: 'scheduled' },
        { text: 'Actual Finish', value: 'actualFinish' },
        { text: 'Finish', value: 'finish' },
      ],
      tripMode: null,
      pointRadius: {
        checkin: 150,
        checkout: 200,
      },
    };
  },

  computed: {
    ...mapState('auth', ['permissions', 'role']),

    isAdmin() {
      return this.role === 'admin';
    },

    fields() {
      return [
        {
          key: 'createdAt',
          label: this.$t('Created at'),
          sortable: true,
          sortDirection: 'desc',
          formatter: (value) => this.$d(value, 'long'),
        },
        {
          key: 'startedAt',
          label: this.$t('Started at'),
          formatter: (value) => this.$d(value, 'time'),
        },
        {
          key: 'finishedAt',
          label: this.$t('Finished at'),
          formatter: (value) => this.$d(value, 'time'),
        },
        { key: 'firstName', label: this.$t('Driver first name') },
        { key: 'lastName', label: this.$t('Driver last name') },
        { key: 'phone', label: this.$t('Driver phone') },
        { key: 'email', label: this.$t('Driver email') },
        {
          key: 'distance',
          label: this.$t('Distance'),
          class: 'text-center',
          formatter: (value) => this.$n(value, 'distance'),
        },
        { key: 'scheduledCount', label: this.$t('Points'), class: 'text-center' },
        { key: 'notScheduledCount', label: this.$t('Not scheduled'), class: 'text-center' },
        {
          key: 'status',
          label: this.$t('Status'),
          class: 'text-center',
          formatter: this.getTripFullStatus,
        },
      ];
    },

    statuses() {
      return Object.entries(companyApi.BillingStatus).map((obj) => ({
        text: this.$t(`BillingStatus.${obj[1]}`),
        value: obj[0],
      }));
    },

    settingsCheckboxes() {
      return [
        'canChangeClientSettings',
        'canChangeTransportTypeOnMobile',
        'isVeeRouteTest',
        'dontAccountDepartureInDownTime',
        'shouldCheckDeviceId',
        'shouldCheckDeviceIdIsRegistered',
        'showUpdateDialog',
        'enableTransportType',
        'enableCashBoxPayment',
        'enablePayedDrivers',
        'checklistNotification',
      ];
    },

    tripPermissionsCheckboxes() {
      return [
        'addPoint',
        'deletePoint',
        'optimize',
        'canChangeTransportType',
        'changeOrderPointsInTrip',
        'changeTimeAtPoint',
        'addToFavorite',
        'deleteFromFavorite',
        'movePoint',
      ];
    },

    showSlotsInput() {
      return this.newStatus === 'ACTIVE';
    },

    days() {
      return moment(this.newBillingDateEnds).diff(new Date(), 'days') + 1;
    },

    breadcrumbs() {
      return [
        {
          text: this.$t('Companies'),
          to: { name: 'companies' },
        },
        {
          text: this.$t('Company') + (this.companyInfo ? ': ' + this.companyInfo.name : ''),
          active: true,
        },
      ];
    },
  },

  async created() {
    this.new = this.$route.params.id === 'new';

    if (!this.new) {
      await this.fetchCompanyInfo();
      await this.getCompanyBillingStatus();

      if (this.statuses.map((el) => el.value).includes(this.currentBillingStatus)) {
        this.newStatus = this.currentBillingStatus;
      }

      if (this.currentBillingDateEnds) {
        this.newBillingDateEnds = this.currentBillingDateEnds;
      }

      if (this.paid_slots) {
        this.newSlotsAmount = this.paid_slots;
      }
    }
  },

  methods: {
    async getCompanyBillingStatus() {
      try {
        const response = await companyApi.getCompanyBillingStatus(this.$route.params.id);

        if (response) {
          this.billingInfo = response;
          if (response.status) {
            this.newStatus = response.status;
            this.currentBillingStatus = response.status;
          }
          if (response.current_period_end) {
            this.currentBillingDateEnds = moment(new Date(response.current_period_end * 1000)).format('YYYY-MM-DD');
          } else {
            this.currentBillingDateEnds = null;
          }
          this.paid_slots = response.paid_slots;
          this.paidDevices = response.paid_devices;
          this.paidTrips = response.paid_trips;
          this.paidDistPoints = response.paid_dist_points;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async updateBillingStatus() {
      try {
        await companyApi.patchBillingStatus(this.companyInfo.external_id, {
          paidDevices: Number(this.paidDevices),
          paidTrips: Number(this.paidTrips),
          paidDistPoints: Number(this.paidDistPoints),
        });
        this.changeBillingStatusSuccess = true;
      } catch (error) {
        this.error = error;
      }
    },

    async fetchCompanyInfo() {
      const response = await companyApi.get(this.$route.params.id);
      this.companyInfo = response.data;
      this.companyId = this.companyInfo.external_id;
      this.tripMode = response.data.settings.tripMode;
      this.canAdminLogin = response.data.canAdminLogin;
      if (response.data.country === 'ru') {
        this.fetchCountOfPaidSms();
        this.fetchCountOfPaidTrips();
      }
    },

    fetchCountOfPaidSms() {
      const fetchData = async () => {
        const res = await companyApi.getCountOfPaidSms(this.$route.params.id);
        this.countOfPaidSms = res.count;
      };
      fetchData();
    },

    fetchCountOfPaidTrips() {
      const fetchData = async () => {
        const res = await companyApi.getCountOfPaidTrips(this.$route.params.id);
        this.countOfPaidTrips = res.count;
      };
      fetchData();
    },

    async fetchCompanyTrips(options) {
      return companyApi.getCompanyTrips(this.$route.params.id, options);
    },

    async onSubmit() {
      try {
        this.error = null;
        this.progress = 1;

        await companyApi.create(this.id, { company: this.company });
        this.$router.push('/companies');
      } catch (err) {
        this.error = err;
      }
      this.progress = 0;
    },

    getTripFullStatus(status) {
      return status;
    },

    async superAdminLogin() {
      try {
        this.error = null;
        const data = await companyApi.adminLogin(this.$route.params.id);
        const url = data.companyUrl + 'login';
        $('body').append('<form action="' + url + '" method="get" target="_blank" id="crossLogin"></form>');
        $('#crossLogin')
          .append('<input type="hidden" name="token" value="' + data.token + '" />')
          .submit()
          .remove();
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    },

    async changeStatus() {
      this.emptyDate = false;
      this.emptyStatus = false;
      this.changingStatusSuccess = null;
      if (this.newBillingDateEnds) {
        switch (this.newStatus) {
          case 'ACTIVE':
            await this.enableActive();
            break;
          case 'TRIAL':
            await this.enableTrial();
            break;
          case 'CONFIDENCE':
            await this.enableConfidence();
            break;
          case 'RESTRICTED':
            await this.enableRestricted();
            break;
          case 'BLOCKED':
            await this.enableBlocked();
            break;
          default:
            this.emptyStatus = true;
        }
        await this.getCompanyBillingStatus();
      } else {
        this.emptyDate = true;
      }
    },

    async enableActive() {
      if (this.newSlotsAmount) {
        try {
          this.emptySlots = false;
          this.error = null;
          const res = await companyApi.enableActive(this.$route.params.id, {
            days: this.days,
            slots: this.newSlotsAmount,
          });
          if (res.success === true) {
            this.changingStatusSuccess = true;
          }
        } catch (err) {
          console.log(err);
          this.error = err;
        }
      } else {
        this.emptySlots = true;
      }
    },

    async enableTrial() {
      try {
        this.error = null;
        const res = await companyApi.enableTrial(this.$route.params.id, { days: this.days });
        if (res.success === true) {
          this.changingStatusSuccess = true;
        }
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    },

    async enableConfidence() {
      try {
        this.error = null;
        const res = await companyApi.enableConfidence(this.$route.params.id, { days: this.days });
        if (res.success === true) {
          this.changingStatusSuccess = true;
        }
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    },

    async enableRestricted() {
      try {
        this.error = null;
        const res = await companyApi.enableRestricted(this.$route.params.id, { days: this.days });
        if (res.success === true) {
          this.changingStatusSuccess = true;
        }
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    },

    async enableBlocked() {
      try {
        this.error = null;
        const res = await companyApi.enableBlocked(this.$route.params.id, { days: this.days });
        if (res.success === true) {
          this.changingStatusSuccess = true;
        }
      } catch (err) {
        console.log(err);
        this.error = err;
      }
    },

    async updateSettings() {
      try {
        this.errorChangeSettings = false;
        this.error = null;

        await companyApi.updateSettings(this.companyInfo.external_id, this.companyInfo.settings);

        this.changeSettingsSuccess = true;
      } catch (err) {
        console.error(err);
        this.errorChangeSettings = true;
      }
    },

    async forceUpdateSettings() {
      try {
        this.errorChangeSettings = false;
        this.error = null;

        await companyApi.forceUpdateSettings(this.companyInfo.external_id);
        this.changeSettingsSuccess = true;
      } catch (err) {
        console.error(err);
        this.errorChangeSettings = true;
      }
    },
  },

  watch: {
    'company.domain'() {
      this.company.url = this.company.domain.replace(/[^a-z]/gi, '');
    },
  },
};
</script>

<style>
.cancel-trip-timepicker .b-time {
  min-width: 200px;
}
</style>
