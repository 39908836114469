<template>
  <div>
    <b-button v-b-modal.modal>{{ $t('Create invoice') }}</b-button>

    <b-modal id="modal" :title="$t('Create invoice')" hide-footer>
      <b-tabs content-class="mt-3" v-if="isRussian">
        <b-tab :title="$t('Create invoice')" @click="reloadInvoice">
          <create-payment-method :id="id" :key="keyInvoice" />
        </b-tab>

        <b-tab :title="$t('Edit')">
          <edit-payment-method :id="id" />
        </b-tab>
      </b-tabs>

      <b-alert show variant="warning" v-else>{{ $t('Foreign company invoices are not supported') }}!</b-alert>
    </b-modal>
  </div>
</template>

<script>
import CreatePaymentMethod from './CreatePaymentMethod';
import EditPaymentMethod from './EditPaymentMethod';

export default {
  components: { CreatePaymentMethod, EditPaymentMethod },

  props: {
    id: {
      type: String,
      required: true,
    },

    country: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    keyInvoice: 0,
  }),

  computed: {
    isRussian() {
      if (this.country) {
        return this.country === 'ru';
      }
      return false;
    },
  },

  methods: {
    reloadInvoice() {
      this.keyInvoice += 1;
    },
  },
};
</script>
