<template>
  <b-container fluid>
    <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    <div class="mb-4">
      <h1 v-if="isCompanyCreate">{{ $t('Company creation') }}</h1>
      <h1 v-else>{{ $t('Editing company') }}</h1>
    </div>

    <b-form @submit.prevent="submitForm()" autocomplete="off">
      <b-form-group
        :label="$t('Company name') + ':'"
        label-for="company-name"
        label-cols-sm="4"
        label-cols-lg="3"
        :invalid-feedback="fieldErrors.title"
        :state="fieldErrors.title ? false : null"
      >
        <b-form-input
          id="company-name"
          v-model="form.title"
          type="text"
          autocomplete="off"
          :state="fieldErrors.title ? false : validation"
          required
        />
      </b-form-group>

      <b-form-group
        :label="$t('Domain') + ':'"
        label-for="domain"
        label-cols-sm="4"
        label-cols-lg="3"
        :invalid-feedback="fieldErrors.domain"
        :state="fieldErrors.domain ? false : null"
      >
        <b-form-input
          id="domain"
          v-model="form.domain"
          type="text"
          autocomplete="off"
          :state="fieldErrors.domain ? false : validation"
          required
        />
      </b-form-group>

      <b-form-group :label="$t('Country') + ':'" label-for="country" label-cols-sm="4" label-cols-lg="3" :state="true">
        <b-form-select id="country" v-model="form.country" :options="countriesList" :state="validation" />
      </b-form-group>

      <b-form-group :label="$t('Owner')" label-size="lg" label-class="mb-2 text-center" class="mb-0">
        <b-form-group
          :label="$t('First name') + ':'"
          label-for="first-name"
          label-cols-sm="4"
          label-cols-lg="3"
          :invalid-feedback="fieldErrors['owner.firstName']"
          :state="fieldErrors['owner.firstName'] ? false : null"
        >
          <b-form-input
            id="first-name"
            v-model="form.owner.firstName"
            type="text"
            autocomplete="off"
            :state="fieldErrors['owner.firstName'] ? false : validation"
            required
          />
        </b-form-group>

        <b-form-group
          :label="$t('Last name') + ':'"
          label-for="last-name"
          label-cols-sm="4"
          label-cols-lg="3"
          :invalid-feedback="fieldErrors['owner.lastName']"
          :state="fieldErrors['owner.lastName'] ? false : null"
        >
          <b-form-input
            id="last-name"
            v-model="form.owner.lastName"
            type="text"
            autocomplete="off"
            :state="fieldErrors['owner.lastName'] ? false : validation"
            required
          />
        </b-form-group>
      </b-form-group>

      <b-form-group :label="$t('Contacts')" label-size="lg" label-class="mb-2 text-center">
        <b-form-group
          :label="$t('E-Mail') + ':'"
          label-for="e-mail"
          label-cols-sm="4"
          label-cols-lg="3"
          :invalid-feedback="fieldErrors['owner.email']"
          :state="fieldErrors['owner.email'] ? false : null"
        >
          <b-form-input
            id="e-mail"
            v-model="form.owner.email"
            type="email"
            autocomplete="off"
            :state="fieldErrors['owner.email'] ? false : validation"
            required
          />
        </b-form-group>

        <b-form-group
          :label="$t('Phone') + ':'"
          label-for="phone"
          label-cols-sm="4"
          label-cols-lg="3"
          :invalid-feedback="fieldErrors['owner.phone']"
          :state="fieldErrors['owner.phone'] ? false : null"
        >
          <b-form-input
            id="phone"
            v-model="form.owner.phone"
            type="tel"
            placeholder="+79990000000"
            autocomplete="chrome-off"
            :state="fieldErrors['owner.phone'] ? false : validation"
            required
          />
        </b-form-group>

        <b-form-group
          :label="$t('Phone') + ' 2:'"
          label-for="phone_2"
          label-cols-sm="4"
          label-cols-lg="3"
          :invalid-feedback="hasFieldPhoneError(0)"
          :state="hasFieldPhoneError(0) ? false : null"
        >
          <b-form-input
            id="phone_2"
            v-model="form.owner.phone_2"
            type="tel"
            placeholder="+79990000000"
            autocomplete="chrome-off"
            :state="hasFieldPhoneError(0) ? false : validation"
          />
        </b-form-group>

        <b-form-group
          :label="$t('Phone') + ' 3:'"
          label-for="phone_3"
          label-cols-sm="4"
          label-cols-lg="3"
          :invalid-feedback="hasFieldPhoneError(1)"
          :state="hasFieldPhoneError(1) ? false : null"
        >
          <b-form-input
            id="phone_3"
            v-model="form.owner.phone_3"
            type="tel"
            placeholder="+79990000000"
            autocomplete="chrome-off"
            :state="hasFieldPhoneError(1) ? false : validation"
          />
        </b-form-group>

        <b-form-group :label="$t('Password') + ':'" label-for="password" label-cols-sm="4" label-cols-lg="3">
          <b-input-group>
            <b-form-input
              id="password"
              v-model="form.owner.password"
              type="text"
              autocomplete="off"
              :state="fieldErrors['owner.password'] ? false : validation"
              :required="isCompanyCreate || canChangePassword"
              :disabled="!isCompanyCreate && !canChangePassword"
            />

            <b-input-group-append>
              <b-btn
                variant="outline-secondary"
                @click="suggestPassword"
                :disabled="!isCompanyCreate && !canChangePassword"
                >{{ $t('Suggest') }}</b-btn
              >
            </b-input-group-append>

            <b-form-invalid-feedback :state="fieldErrors['owner.password'] ? false : null">
              {{ fieldErrors['owner.password'] }}
            </b-form-invalid-feedback>
          </b-input-group>
          <b-form-checkbox v-if="!isCompanyCreate" v-model="canChangePassword">{{
            $t('Change password')
          }}</b-form-checkbox>
        </b-form-group>
      </b-form-group>

      <b-alert v-if="error" show variant="danger">{{ error.message }}</b-alert>

      <div>
        <b-btn type="submit" :progress="progress" variant="primary">{{ $t('Submit') }}</b-btn>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import { cloneDeep } from 'lodash';
import api from '@/api/company';
import { getCountriesList } from '@/i18n';
import { collectAllErrors } from '@/api/utils';
import { suggestPassword } from '@/lib/suggest';

export default {
  name: 'CompanyEdit',

  data() {
    return {
      company: null,
      form: {
        title: '',
        domain: '',
        country: 'ru',
        owner: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          phone_2: '',
          phone_3: '',
          password: '',
        },
      },
      error: '',
      progress: 0,
      canChangePassword: false,
    };
  },

  methods: {
    async fetchCompanyInfo() {
      const { data } = await api.get(this.$route.params.id);

      this.form.title = data.name;
      this.form.domain = data.domain;
      this.form.country = data.country;
      this.form.owner.firstName = data.owner.first_name;
      this.form.owner.lastName = data.owner.last_name;
      this.form.owner.email = data.owner.email;
      this.form.owner.phone = data.owner.phone;

      if (Array.isArray(data.owner.contacts) && data.owner.contacts.length) {
        for (const [idx, { phone, note }] of data.owner.contacts.entries()) {
          this.form.owner['phone_' + (idx + 2)] = phone;
        }
      }

      this.company = data;

      return data;
    },

    async submitForm() {
      try {
        this.progress = 1;

        const newForm = cloneDeep(this.form);

        const contacts = [];
        for (const phone of [newForm.owner.phone_2, newForm.owner.phone_3]) {
          if (phone) {
            contacts.push({
              phone,
            });
          }
        }
        newForm.owner.contacts = contacts;

        delete newForm.owner.phone_2;
        delete newForm.owner.phone_3;

        let res;

        if (this.isCompanyCreate) {
          res = await api.create(newForm);
          this.toast(this.$t('Company has been created'));
        } else {
          if (this.canChangePassword === false) {
            delete newForm.owner.password;
          }
          res = await api.update(this.$route.params.id, newForm);
          this.toast(this.$t('Company has been edited'));
        }
        this.$router.push(`/companies/${res.dbId}`);
      } catch (err) {
        this.error = err;
      } finally {
        this.progress = 0;
      }
    },

    suggestPassword() {
      this.form.owner.password = suggestPassword();
    },

    toast(text) {
      this.$root.$bvToast.toast(text, {
        title: this.$t('Status'),
        variant: 'success',
        autoHideDelay: 2000,
      });
    },

    selectChars(passwordCharacter, length) {
      return passwordCharacter
        .split('')
        .sort(() => 0.5 - Math.random())
        .join('')
        .substr(0, length);
    },

    hasFieldPhoneError(idx) {
      return this.fieldErrors[`owner.contacts.${idx}.phone`];
    },

    hasFieldInternalPhoneError(idx) {
      return this.fieldErrors['owner.contacts.${idx}.note'];
    },
  },

  created() {
    if (!this.isCompanyCreate) {
      this.fetchCompanyInfo();
    }
  },

  computed: {
    countriesList() {
      return getCountriesList().map((obj) => ({ text: obj.name, value: obj.alpha2 }));
    },

    fieldErrors() {
      if (this.error && this.error.errors) {
        return collectAllErrors(this.error.errors);
      }
      return {};
    },

    validation() {
      return this.error ? true : null;
    },

    isCompanyCreate() {
      return this.$route.name === 'companyCreate';
    },

    breadcrumbs() {
      const items = [
        {
          text: this.$t('Companies'),
          to: { name: 'companies' },
        },
      ];

      if (this.isCompanyCreate) {
        items.push({
          text: this.$t('Company creation'),
          active: true,
        });
      } else {
        items.push({
          text: this.$t('Company') + (this.company ? ': ' + this.company.name : ''),
          to: { name: 'company', params: { id: this.$route.params.id } },
        });
        items.push({
          text: this.$t('Editing company'),
          active: true,
        });
      }

      return items;
    },
  },

  watch: {
    canChangePassword() {
      this.form.owner.password = '';
    },
  },
};
</script>
