import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import auth from '@/api/auth';

Vue.use(Router);
Vue.use(Meta);

const route = (path, file, name, children) => ({
  exact: true,
  path,
  name,
  children,
  component: require(`./pages/${file}.vue`).default,
});

const router = new Router({
  routes: [
    route('/login', 'Login', 'login'),
    route('/logout', 'Logout', 'logout'),
    route('/', 'Main', null, [
      route('/', 'Dashboard', 'dashboard'),
      route('/users', 'Users', 'users'),
      route('/users/:id', 'UserDetails', 'user'),
      route('/companies', 'Companies', 'companies'),
      route('/companies/create', 'CompanyEdit', 'companyCreate'),
      route('/companies/:id', 'CompanyDetails', 'company'),
      route('/companies/:id/settingsLogs', 'CompanySettingsLogs', 'companySettingsLogs'),
      route('/companies/:id/distributionUsage', 'DistributionUsage', 'distributionUsage'),
      route('/companies/:id/edit', 'CompanyEdit', 'companyEdit'),
      route('/companies/:id/rules', 'CompanyRules', 'companyRules'),
      route('/invoices', 'Invoice', 'invoices'),
      route('/requests', 'Requests', 'requests'),
      route('/errors', 'Errors', 'errors'),
      route('/logs', 'Logs', 'logs'),
      route('/info', 'Info', 'info'),
      route('/distributions', 'Distributions', 'distributions'),
    ]),
    route('*', 'NotFound', 'notFound'),
  ],
  mode: 'history',
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'notFound') {
    return next();
  }

  await auth.waitInit();

  if (to.matched.every((record) => record.name !== 'login')) {
    if (!auth.loggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
