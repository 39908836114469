<template>
  <div>
    <div class="mb-4">
      <h1>{{ login }}</h1>
    </div>

    <b-container fluid>
      <b-form @submit.prevent="onSubmit" validated>
        <b-form-group v-if="this.new" label-for="inputLogin" :label="$t('Login')">
          <b-form-input id="inputLogin" v-model="login" type="text" class="form-control" :disabled="!canEdit" />
        </b-form-group>

        <b-form-group label-for="inputFirstName" :label="$t('First name')">
          <b-form-input
            id="inputFirstName"
            v-model="first_name"
            type="text"
            class="form-control"
            :disabled="!canEdit"
          />
        </b-form-group>

        <b-form-group label-for="inputLastName" :label="$t('Last name')">
          <b-form-input id="inputLastName" v-model="last_name" type="text" class="form-control" :disabled="!canEdit" />
        </b-form-group>

        <b-row v-if="canEdit">
          <b-col :cols="this.new ? 12 : 8">
            <b-form-group label-for="inputPassword" :label="$t('Password')">
              <b-form-input
                id="inputPassword"
                v-model="password"
                type="password"
                class="form-control"
                :disabled="!changePassword && !this.new"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="!this.new" align-self="center">
            <b-form-checkbox v-model="changePassword">
              {{ $t('Change password') }}
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-form-group label-for="inputRole" :label="$t('Role')">
          <b-form-select v-model="role" :options="roleOptions" :disabled="!canEdit" />
        </b-form-group>

        <b-form-group :label="$t('Allow access to')">
          <div v-for="perm of permissionOptions" :key="perm.value">
            <b-form-checkbox v-model="permissions[perm.value]" :disabled="!canEdit">
              {{ perm.text }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <v-submit :progress="progress" v-if="canEdit">{{ $t('Save') }}</v-submit>
        <b-button variant="danger" v-if="!this.new && can_delete" @click="showModal">{{ $t('Delete') }}</b-button>
      </b-form>

      <b-modal ref="modal" hide-header hide-footer>
        <div class="d-block text-center">
          {{ $t('Are you sure to delete user?') }}
        </div>
        <b-button class="mt-3" block @click="hideModal">{{ $t('Close') }}</b-button>
        <b-button class="mt-2" block variant="danger" @click="onDelete">{{ $t('Delete') }}</b-button>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'UserDetails',
  metaInfo: {
    title: 'Users',
  },
  data() {
    return {
      id: '',
      login: '',
      first_name: '',
      last_name: '',
      password: '',
      role: 'admin',
      can_update: false,
      can_delete: false,
      progress: 0,
      error: false,
      errorText: '',
      new: false,
      changePassword: false,
      permissions: {
        accessRussianCompanies: false,
        accessForeignCompanies: false,
        accessTrialCompanies: false,
        accessActiveCompanies: false,
        accessCompanyTrips: false,
        adminLogin: false,
        adminLoginSupport: false,
      },
    };
  },
  computed: {
    canEdit() {
      return this.new || this.can_update;
    },
    roleOptions() {
      return [
        { text: this.$t('Please select role'), value: null },
        { text: this.$t('Admin'), value: 'admin' },
        { text: this.$t('Manager'), value: 'manager' },
      ];
    },
    permissionOptions() {
      return [
        { text: this.$t('Russian companies'), value: 'accessRussianCompanies' },
        { text: this.$t('Foreign companies'), value: 'accessForeignCompanies' },
        { text: this.$t('Companies on trial period'), value: 'accessTrialCompanies' },
        { text: this.$t('Companies on active period'), value: 'accessActiveCompanies' },
        { text: this.$t('Company detail info'), value: 'accessCompanyTrips' },
        { text: this.$t('Access to financial info'), value: 'accessFinancialInfo' },
        { text: this.$t('Access to logs'), value: 'accessLogs' },
        { text: this.$t('Ability to login as client superadmin'), value: 'adminLogin' },
        {
          text: this.$t('Ability to login as client superadmin with permission for support'),
          value: 'adminLoginSupport',
        },
      ];
    },
  },
  created() {
    this.new = this.$route.params.id === 'create';
    if (!this.new) {
      this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      const data = await api.user.get(this.$route.params.id);
      Object.assign(this, data);
    },

    async onSubmit() {
      try {
        this.error = false;
        this.progress = 1;
        const userData = {
          first_name: this.first_name,
          last_name: this.last_name,
          role: this.role,
          permissions: this.permissions,
        };

        if (!this.new) {
          if (this.changePassword) {
            userData.password = this.password;
          }
          await api.user.update(this.id, userData);
        } else {
          userData.login = this.login;
          userData.password = this.password;
          await api.user.create(userData);
        }

        this.$router.push('/users');
      } catch (err) {
        this.error = true;
        this.errorText = err.message;
      }
      this.progress = 0;
    },

    async onDelete() {
      await api.user.remove(this.id);
      this.$router.push('/users');
    },

    showModal() {
      this.$refs['modal'].show();
    },

    hideModal() {
      this.$refs['modal'].hide();
    },
  },
};
</script>
