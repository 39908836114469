import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

const mock = new MockAdapter(axios, { delayResponse: 200 });
const baseURL = '/api/v1';

mock
  .onPost(baseURL + '/verify', {
    login: '111',
    password: '222',
  })
  .reply(200, {
    status: 'ok',
  });
// Set-Cookie token HttpOnly Secure
// Set-Cookie xsrfToken='d9b9714c-7ac0-42e0-8696-2dae95dbc33e'

mock.onPost(baseURL + '/verify').reply(401, {
  status: 'error',
});

mock.onGet(baseURL + '/userInfo').replyOnce(403);

mock.onGet(baseURL + '/userInfo').reply(200, {
  name: 'User name',
  avatar: 'https://randomuser.me/api/portraits/men/85.jpg',
});

mock.onGet(baseURL + '/users').reply(200, {
  users: [
    { id: 1, name: 'John Smith' },
    { id: 2, name: 'John Gray' },
  ],
});

mock.onGet(baseURL + '/user/1').reply(200, {
  id: 1,
  name: 'John Smith',
});

mock.onGet(baseURL + '/user/2').reply(200, {
  id: 2,
  name: 'John Gray',
});

mock.onPut(baseURL + '/user/1').reply(200, {
  status: 'ok',
});
